import React from 'react';
import Marquee from 'react-fast-marquee';
import { v4 as uuidv4 } from 'uuid';
import { RequirementsProps } from '../../tools/context';
import { eventTracking } from '../../tools/eventTracking';

export interface Suggestion {
  _key?: string;
  text: string;
  link: string;
}

export interface ScrollingEngineSuggestionsProps {
  requirements: RequirementsProps;
  suggestions: Suggestion[];
}

const Renderer = ({ requirements, suggestions }: ScrollingEngineSuggestionsProps) => {
  if (!suggestions?.length) return null;

  return (
    <div className="ScrollingEngineSuggestions">
      <Marquee speed={60} delay={0} loop={0} pauseOnHover={true}>
        <ul className="ScrollingEngineSuggestions__list">
          {suggestions.map((suggestion, index) => (
            <li className="ScrollingEngineSuggestions__list__item" key={suggestion._key || index}>
              <a
                onClick={() => eventTracking('interaction', 'clic-suggestion', 'clic-suggestion')}
                href={`/engine#/engine/charentes-engine/chat?chat_id=${uuidv4()}&query=${encodeURIComponent(suggestion.text)}`}
              >
                {suggestion.text}
              </a>
            </li>
          ))}
        </ul>
      </Marquee>
    </div>
  );
};

const preloader = async (data: any, requirements: RequirementsProps) => {
  return data;
};

const ScrollingEngineSuggestions = { Renderer, preloader };

export default ScrollingEngineSuggestions;
export { Renderer, preloader };
