import fetch from 'node-fetch';
import { pictos } from '../components/Picto';
import { DetailShopV2Props } from '../components/ShopV2/Detail';
import { ManagementStoreV2Type } from '../components/ShopV2/Management';
import { Disability } from '../components/TravelPlanerResults/SitesExplorator';
import { RequirementsProps } from './context';

export type StoreInfoProvider = 'apidae';

const langs = [
  { key: 'fr', objectKey: 'libelleFr' },
  { key: 'en', objectKey: 'libelleEn' },
  { key: 'de', objectKey: 'libelleDe' },
  { key: 'nl', objectKey: 'libelleNl' },
  { key: 'it', objectKey: 'libelleIt' },
  { key: 'es', objectKey: 'libelleEs' },
  { key: 'ru', objectKey: 'libelleRu' },
  { key: 'zh', objectKey: 'libelleZh' },
  { key: 'pt-br', objectKey: 'libellePtBr' },
  { key: 'ja', objectKey: 'libelleJa' },
];

type CustomDataStoreLang = (typeof langs)[number]['key'];
export type CustomDataStoreWithLangs = {
  [key in CustomDataStoreLang]: CustomDataStore;
};

export interface CustomDataStore {
  store?: {
    name?: string;
    description?: string;
    address?: {
      address1?: string;
      address2?: string;
      postalCode?: string;
      city?: string;
      country?: string;
      latitude?: number;
      longitude?: number;
    };
    phone?: string;
    email?: string;
    website?: string;
    schedule?: string;
    facebook?: string;
    instagram?: string;
    twitter?: string;
    tiktok?: string;
    pinterest?: string;
    youtube?: string;
    tarifs?: string;
    minimumPrice?: number;
    maximumPrice?: number;
    management?: ManagementStoreV2Type;
    images?: ImageType[];
    handicaps?: Disability[];
    labels?: (keyof typeof pictos)[];
  };
  groups?: CustomStoreGroup[];
}

export interface CustomStoreGroup {
  title?: string;
  anchor?: string;
  items?: CustomStoreItem[];
}

type CustomStoreItem =
  | TextStoreItem
  | TextListStoreItem
  | ImageListStoreItem
  | TableStoreItem
  | DetailsStoreItem;

export interface TextStoreItem {
  type: 'text';
  text: string; // dangerouslySetInnerHTML
}

export interface TextListStoreItem {
  type: 'textList';
  withBullets?: boolean;
  texts: string[]; // dangerouslySetInnerHTML
}

export interface ImageType {
  url: string;
  alt: string;
}

export interface ImageListStoreItem {
  type: 'imageList';
  images: ImageType[];
}

export interface TableStoreItem {
  type: 'table';
  title?: string;
  rows: string[][]; // dangerouslySetInnerHTML
}

export interface DetailsStoreItem {
  type: 'details';
  details: DetailShopV2Props[];
}

type GetStoreInfoFromProviderFn = (
  requirements: RequirementsProps,
  provider: StoreInfoProvider,
  id: string
) => Promise<CustomDataStoreWithLangs>;
const getStoreInfoFromProvider: GetStoreInfoFromProviderFn = async (requirements, provider, id) => {
  switch (provider) {
    case 'apidae':
      return await getStoreInfoFromApidae(id, requirements.config?.apidae);
    default:
      return {} as CustomDataStoreWithLangs;
  }
};

interface ApidaeConfig {
  projectId: number;
  apiKey: string;
}

type GetStoreInfoFromApidaeFn = (
  id: string,
  config: ApidaeConfig
) => Promise<CustomDataStoreWithLangs>;
const getStoreInfoFromApidae: GetStoreInfoFromApidaeFn = async (id, config) => {
  const { projectId, apiKey } = config || {};

  try {
    const apidaeData = await fetch(
      `https://api.apidae-tourisme.com/api/v002/objet-touristique/get-by-id/${id}?responseFields=@all&locales=fr,en,de,nl,it,es,ru,zh,pt-br,ja&apiKey=${apiKey}&projetId=${projectId}`
    );
    const data = await apidaeData.json();

    const customDataWithLangs: CustomDataStoreWithLangs = langs.reduce(
      (acc, { key: lang, objectKey: key }) => {
        const getMoyenCommunication = (type: string) =>
          data?.informations?.moyensCommunication?.find((c: any) => c?.type?.libelleFr === type)
            ?.coordonnees?.fr ?? null;

        let minimumPrice = data?.descriptionTarif?.gratuit
          ? 0
          : Math.min(
              ...(data?.descriptionTarif?.periodes?.flatMap((p: any) =>
                p.tarifs
                  ?.filter(
                    (t: any) =>
                      t.type?.libelleFr !== 'Taxe de séjour' &&
                      t.type?.libelleFr !== 'Petit déjeuner'
                  )
                  ?.map((t: any) => t.minimum || Infinity)
              ) ?? [Infinity])
            );
        if (minimumPrice === Infinity) minimumPrice = null;

        let maximumPrice = Math.max(
          ...(data?.descriptionTarif?.periodes?.flatMap((p: any) =>
            p.tarifs?.map((t: any) => t.maximum || 0)
          ) ?? [0])
        );
        if (maximumPrice === 0) maximumPrice = null;

        let customData: CustomDataStore = {
          store: {
            name: data?.nom?.[key] || data?.nom?.libelleFr,
            description:
              data?.presentation?.descriptifDetaille?.[key] ??
              data?.presentation?.descriptifDetaille?.libelleFr ??
              data?.presentation?.descriptifCourt?.[key] ??
              data?.presentation?.descriptifCourt?.libelleFr,
            address: {
              address1: data?.localisation?.adresse?.adresse1,
              address2: data?.localisation?.adresse?.adresse2,
              postalCode: data?.localisation?.adresse?.codePostal,
              city: data?.localisation?.adresse?.commune?.nom,
              country:
                data?.localisation?.adresse?.commune?.pays?.[key] ??
                data?.localisation?.adresse?.commune?.pays?.libelleFr,
              latitude: data?.localisation?.geolocalisation?.geoJson?.coordinates[1],
              longitude: data?.localisation?.geolocalisation?.geoJson?.coordinates[0],
            },
            phone: getMoyenCommunication('Téléphone'),
            email: getMoyenCommunication('Mél'),
            website: getMoyenCommunication('Site web (URL)'),
            schedule:
              data?.ouverture?.periodeEnClair?.[key] ?? data?.ouverture?.periodeEnClair?.libelleFr,
            tarifs: data?.descriptionTarif?.tarifsEnClair?.[key],
            minimumPrice,
            maximumPrice,
            management: {
              name: data?.gestion?.membreProprietaire?.nom,
              email: data?.gestion?.signalerUnProblemeMails?.[0],
              website: data?.gestion?.membreProprietaire?.siteWeb,
              updatedAt: data?.gestion?.dateModification,
              createdAt: data?.gestion?.dateCreation,
            },
            images: data?.illustrations?.map((i: any) =>
              i?.traductionFichiers?.[0]
                ? {
                    url: i.traductionFichiers[0].urlQHD || i.traductionFichiers[0].url,
                    alt: i.traductionFichiers[0].fileName,
                  }
                : null
            ),
            handicaps: Object.keys(data?.prestations || {})
              ?.map((p: any) => {
                const map = {
                  surchargeDescriptifHandicapAuditif: 'AUDITIF',
                  surchargeDescriptifHandicapMental: 'MENTAL',
                  surchargeDescriptifHandicapMoteur: 'MOTEUR',
                  surchargeDescriptifHandicapVisuel: 'VISUEL',
                };

                if (map[p] && data.prestations[p] === true) return map[p];

                return null;
              })
              ?.filter((d) => d),
            labels: [],
          },
          groups: [],
        };

        customData.store.labels = (
          [
            data?.informationsRestauration?.chaines?.find(
              (c: any) => c?.libelleFr === 'Maîtres restaurateurs'
            )
              ? 'maitresRestaurateurs'
              : null,
            data?.informationsRestauration?.chaines?.find(
              (c: any) => c?.libelleFr === 'Relais et Châteaux'
            )
              ? 'relaisEtChateaux'
              : null,
            data?.informationsRestauration?.chaines?.find(
              (c: any) => c?.libelleFr === 'Les Grandes Tables du Monde'
            )
              ? 'lesGrandesTablesDuMonde'
              : null,
            data?.informationsRestauration?.classementsGuides?.find(
              (c: any) => c?.libelleFr === 'Etoile verte 2024'
            )
              ? 'etoileVerteMichelin'
              : null,
            data?.presentation?.typologiesPromoSitra?.some(
              (t: any) => t.libelleFr === 'Label Vignobles et Découvertes'
            )
              ? 'vignoblesEtDecouvertes'
              : null,
          ] as CustomDataStore['store']['labels']
        )?.filter((l) => l);

        // Activités sur place:

        // Informations Hôtellerie:
        const infosHotel = data?.informationsHotellerie;
        if (infosHotel) {
          const texts = [
            !!infosHotel.capacite?.nombreChambreClasses
              ? `{{t:nombreChambreClasses}} : <strong>${infosHotel.capacite.nombreChambreClasses}</strong>`
              : null,
            !!infosHotel.capacite?.nombreTotalPersonnesReel
              ? `{{t:nombreTotalPersonnesReel}} : <strong>${infosHotel.capacite.nombreTotalPersonnesReel}</strong>`
              : null,
            !!infosHotel.capacite?.nombreChambresDoubles
              ? `{{t:nombreChambresDoubles}} : <strong>${infosHotel.capacite.nombreChambresDoubles}</strong>`
              : null,
            !!infosHotel.capacite?.nombreSuites
              ? `{{t:nombreSuites}} : <strong>${infosHotel.capacite.nombreSuites}</strong>`
              : null,
            !!infosHotel.capacite?.nombreChambresMobiliteReduite
              ? `{{t:nombreChambresMobiliteReduite}} : <strong>${infosHotel.capacite.nombreChambresMobiliteReduite}</strong>`
              : null,
            !!infosHotel.capacite?.nombreAppartements
              ? `{{t:nombreAppartements}} : <strong>${infosHotel.capacite.nombreAppartements}</strong>`
              : null,
          ].filter((t) => t);

          if (texts.length) {
            customData.groups.push({
              title: '{{t:hotelInformations}}',
              items: [
                {
                  type: 'textList',
                  texts,
                },
              ],
            });
          }
        }

        // Équipements et services:
        if (
          data?.prestations?.equipements?.length ||
          data?.prestations?.services?.length ||
          data?.prestation?.conforts?.length
        ) {
          customData.groups.push({
            title: '{{t:equipmentsAndServices}}',
            items: [
              {
                type: 'textList',
                withBullets: true,
                texts: [
                  ...(data?.prestations?.equipements?.map((e: any) => e?.[key]) ?? []),
                  ...(data?.prestations?.services?.map((s: any) => s?.[key]) ?? []),
                  ...(data?.prestations?.conforts?.map((c: any) => c?.[key]) ?? []),
                ],
              },
              data?.prestations?.complementAccueil?.[key]
                ? {
                    type: 'text',
                    text: `&#9432; ${data.prestations.complementAccueil[key]}`,
                  }
                : null,
            ],
          });
        }

        // Informations Fête et Manifestations:
        if (data?.informationsFeteEtManifestation?.themes?.length) {
          customData.groups.push({
            title: '{{t:themes}}',
            items: [
              {
                type: 'textList',
                withBullets: true,
                texts: data.informationsFeteEtManifestation.themes.map((t: any) => t?.[key]),
              },
            ],
          });
        }

        // Environnement:
        if (data?.localisation?.environnements?.length) {
          customData.groups.push({
            title: '{{t:environment}}',
            items: [
              {
                type: 'textList',
                withBullets: true,
                texts: data.localisation.environnements.map((e: any) => e?.[key]),
              },
            ],
          });
        }

        // Langues parlées:
        if (data?.prestations?.languesParlees?.length) {
          customData.groups.push({
            title: '{{t:spokenLanguages}}',
            items: [
              {
                type: 'textList',
                withBullets: true,
                texts: data.prestations.languesParlees.map((l: any) => l?.[key]),
              },
            ],
          });
        }

        // Horaires:
        if (customData?.store?.schedule) {
          customData.groups.push({
            title: '{{t:schedules}}',
            anchor: 'schedules',
            items: [
              {
                type: 'textList',
                texts: customData.store.schedule.split('\n\n'),
              },
            ],
          });
        }

        // Tarifs:
        if (data?.descriptionTarif?.tarifsEnClair) {
          const tarifsTaxes = data?.descriptionTarif?.tarifsEnClair?.[key]?.split('\n\n\n');
          if (tarifsTaxes?.length) {
            customData.groups.push({
              title: '{{t:prices}}',
              anchor: 'prices',
              items: [
                {
                  type: 'table',
                  rows:
                    tarifsTaxes[0]
                      ?.split('\n')
                      ?.filter((t: string) => t)
                      ?.map((t: string) => (t.endsWith('.') ? t.slice(0, -1) : t))
                      ?.map((t: string) => t.split(': '))
                      ?.map((t: string[]) =>
                        t.length > 2
                          ? [
                              t[0],
                              `<strong>${t
                                ?.slice(1)
                                ?.join(' : ')
                                ?.replace(/\(([^)]+)\)\.?$/, (_, p) => `<p>(${p})</p>`)
                                ?.replace(
                                  /(€)([^<]*)$/,
                                  (match, euroSign, textAfterEuro) =>
                                    `${euroSign} <p>${textAfterEuro}</p>`
                                )}</strong>`,
                            ]
                          : [
                              t[0],
                              !!t[1]
                                ? `<strong>${t[1]
                                    .replace(/\(([^)]+)\)\.?$/, (_, p) => `<p>(${p})</p>`)
                                    .replace(
                                      /(€)([^<]*)$/,
                                      (match, euroSign, textAfterEuro) =>
                                        `${euroSign} <p>${textAfterEuro}</p>`
                                    )}</strong>`
                                : '',
                            ]
                      ) ?? [],
                },
                {
                  type: 'textList',
                  texts: [tarifsTaxes[1]],
                },
              ],
            });
          }
        }

        // Visites:
        if (
          data?.visites?.languesVisite ||
          data?.visites?.prestationsVisitesIndividuelles?.length ||
          data?.visites?.prestationsVisitesGroupees?.length
        ) {
          customData.groups.push({
            title: '{{t:visits}}',
            anchor: 'prices',
            items: [
              {
                type: 'table',
                rows: [
                  data?.visites?.languesVisite
                    ? [
                        data.visites.languesVisite?.length > 1
                          ? '{{t:visitSpokenLanguages}}'
                          : '{{t:visitSpokenLanguage}}',
                        `<strong>${data.visites.languesVisite?.map((l: any) => l?.[key])?.join(', ')}</strong>`,
                      ]
                    : null,
                  data?.visites?.prestationsVisitesIndividuelles?.length
                    ? [
                        '{{t:individualVisits}}',
                        `<strong>${data.visites.prestationsVisitesIndividuelles
                          ?.map((p: any) => p?.[key])
                          ?.join(', ')}</strong>`,
                      ]
                    : null,
                  data?.visites?.prestationsVisitesGroupees?.length
                    ? [
                        '{{t:groupedVisits}}',
                        `<strong>${data.visites.prestationsVisitesGroupees?.map((p: any) => p?.[key])?.join(', ')}</strong>`,
                      ]
                    : null,
                ],
              },
            ],
          });
        }

        // Moyens de paiement:
        if (data?.descriptionTarif?.modesPaiement?.length) {
          customData.groups.push({
            title: '{{t:paymentMethods}}',
            items: [
              {
                type: 'textList',
                withBullets: true,
                texts: data.descriptionTarif.modesPaiement.map((p: any) => p?.[key]),
              },
            ],
          });
        }

        // Réseaux sociaux:
        if (data?.informations?.moyensCommunication?.length) {
          const socials = [
            { id: 'Page facebook', name: 'facebook', icon: 'facebookV2' },
            { id: 'Instagram', name: 'instagram', icon: 'instagramV2' },
            { id: 'Réseau social ex-Twitter', name: 'twitter', icon: 'twitter' },
            { id: 'TikTok', name: 'tiktok', icon: 'tiktok' },
            { id: 'Pinterest', name: 'pinterest', icon: 'pinterest' },
            { id: 'Chaîne Youtube', name: 'youtube', icon: 'youtubeV2' },
          ];

          const socialGroup = {
            title: '{{t:socialNetworks}}',
            items: [
              {
                type: 'details',
                details: socials
                  .map(({ id, name, icon }) => {
                    const value = getMoyenCommunication(id);
                    if (!value) return null;

                    return {
                      icon: icon ?? name,
                      title: name,
                      value,
                      link: { href: value },
                    } as DetailShopV2Props;
                  })
                  ?.filter((d) => d),
              } as DetailsStoreItem,
            ],
          };

          if (socialGroup.items[0]?.details.length) customData.groups.push(socialGroup);
        }

        acc[lang] = customData;
        return acc;
      },
      {} as CustomDataStoreWithLangs
    );

    // Remove the duplicates langs (same description[:50])
    const customDataWithLangsFiltered: CustomDataStoreWithLangs = {};
    Object.keys(customDataWithLangs).forEach((lang) => {
      const description = customDataWithLangs[lang]?.store?.description;
      if (!description && !customDataWithLangs[lang]?.groups?.length) return;

      const isDuplicate = Object.keys(customDataWithLangsFiltered).some(
        (l) => customDataWithLangsFiltered[l]?.store?.description === description
      );
      if (!isDuplicate) customDataWithLangsFiltered[lang] = customDataWithLangs[lang];
    });

    return customDataWithLangsFiltered;
  } catch (error) {
    console.error('Error while fetching data from Apidae', error);
    return {} as CustomDataStoreWithLangs;
  }
};

export { getStoreInfoFromProvider };
