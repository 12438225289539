import type { SVGProps } from 'react';
import * as React from 'react';
import { useWindowSize } from '../../tools/hooks';
import { getCities } from './cities';

interface CarteOnProps extends SVGProps<SVGSVGElement> {
  highlightedPath?:
    | 'flowvelo'
    | 'scandiberique'
    | 'velodyssee'
    | 'canal2mer'
    | 'francette'
    | 'velideale'
    | null;
  color?: string;
  routes: {
    flowvelo?: { color: { hex: string }; link: string; description: string };
    scandiberique?: { color: { hex: string }; link: string; description: string };
    velodyssee?: { color: { hex: string }; link: string; description: string };
    canal2mer?: { color: { hex: string }; link: string; description: string };
    francette?: { color: { hex: string }; link: string; description: string };
    velideale?: { color: { hex: string }; link: string; description: string };
  };
  cityLinks: any;
}

const SvgCarteOn: React.FC<CarteOnProps> = ({ routes, highlightedPath, cityLinks, className }) => {
  const { isMobile } = useWindowSize();
  const citiesObject = getCities(cityLinks);

  const citiesArray = Object.values(citiesObject).map((city) => ({
    id: city.id,
    name: city.name,
    link: city.link,
    path: city.path,
    fill: city.fill,
    className: city.className,
  }));

  // console.log('routes', routes);
  // console.log('cityLinks', cityLinks);
  // console.log('getCities', getCities(cityLinks));

  const DEFAULT_STROKE = '';
  const PATHS = [
    'flowvelo',
    'scandiberique',
    'velodyssee',
    'canal2mer',
    'francette',
    'velideale',
  ] as const;

  const strokeStyles = Object.fromEntries(
    PATHS.map((path) => [
      path,
      { stroke: highlightedPath === path || isMobile ? routes[path]?.color?.hex : DEFAULT_STROKE },
    ])
  );

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 939.1 823.8" className={className}>
      <defs>
        <pattern
          id="Carte_svg__Nouveau_motif_2"
          width={54.9}
          height={56}
          x={0}
          y={0}
          data-name="Nouveau motif 2"
          patternTransform="matrix(.7 0 0 .7 -2254.2 -2375.1)"
          patternUnits="userSpaceOnUse"
          viewBox="0 0 54.9 56"
        >
          <path
            d="M0 0h54.9v56H0z"
            style={{
              fill: 'none',
            }}
          />
          <path
            d="M54.9 52.5c.7 9.3 14.9 9.3 15.5 0-1.4 8.7 13.6 9.6 13.1.8.4 8 12.3 8.2 14.2-.8-.1 4.3 2.5 7 5.8 7s5.8-3.4 6.2-7M0 52.5c.7 9.3 14.9 9.3 15.5 0-1.4 8.7 13.6 9.6 13.1.8.4 8 12.3 8.2 14.2-.8-.1 4.3 2.5 7 5.8 7s5.8-3.4 6.2-7"
            className="Carte_svg__st8"
          />
          <path
            d="M-54.9 52.5c.7 9.3 14.9 9.3 15.5 0-1.4 8.7 13.6 9.6 13.1.8.4 8 12.3 8.2 14.2-.8-.1 4.3 2.5 7 5.8 7s5.9-3.4 6.3-7M27.5 38.5c.7 9.3 14.9 9.3 15.5 0-1.4 8.7 13.6 9.6 13.1.8.4 8 12.3 8.2 14.2-.8-.1 4.3 2.5 7 5.8 7s5.8-3.4 6.2-7M-27.4 38.5c.7 9.3 14.9 9.3 15.5 0-1.4 8.7 13.6 9.6 13.1.8.4 8 12.3 8.2 14.2-.8-.1 4.3 2.5 7 5.8 7s5.8-3.4 6.2-7M54.9 24.5c.7 9.3 14.9 9.3 15.5 0-1.4 8.7 13.6 9.6 13.1.8.4 8 12.3 8.2 14.2-.8-.1 4.3 2.5 7 5.8 7s5.8-3.4 6.2-7M0 24.5c.7 9.3 14.9 9.3 15.5 0-1.4 8.7 13.6 9.6 13.1.8.4 8 12.3 8.2 14.2-.8-.1 4.3 2.5 7 5.8 7s5.8-3.4 6.2-7"
            className="Carte_svg__st8"
          />
          <path
            d="M-54.9 24.5c.7 9.3 14.9 9.3 15.5 0-1.4 8.7 13.6 9.6 13.1.8.4 8 12.3 8.2 14.2-.8-.1 4.3 2.5 7 5.8 7s5.9-3.4 6.3-7M27.5 10.5c.7 9.3 14.9 9.3 15.5 0-1.4 8.7 13.6 9.6 13.1.8.4 8 12.3 8.2 14.2-.8-.1 4.3 2.5 7 5.8 7s5.8-3.4 6.2-7M-27.4 10.5c.7 9.3 14.9 9.3 15.5 0-1.4 8.7 13.6 9.6 13.1.8.4 8 12.3 8.2 14.2-.8-.1 4.3 2.5 7 5.8 7s5.8-3.4 6.2-7M54.9-3.5c.7 9.3 14.9 9.3 15.5 0C69 5.2 84 6.1 83.5-2.7c.4 8 12.3 8.2 14.2-.8-.1 4.3 2.5 7 5.8 7s5.8-3.4 6.2-7M0-3.5c.7 9.3 14.9 9.3 15.5 0-1.4 8.7 13.6 9.6 13.1.8.4 8 12.3 8.2 14.2-.8-.1 4.3 2.5 7 5.8 7s5.8-3.4 6.2-7M-54.9-3.5c.7 9.3 14.9 9.3 15.5 0-1.4 8.7 13.6 9.6 13.1.8.4 8 12.3 8.2 14.2-.8-.1 4.3 2.5 7 5.8 7S-.4.1 0-3.5"
            className="Carte_svg__st8"
          />
        </pattern>
      </defs>
      <g id="Carte_svg__Carte">
        <path
          id="Carte_svg__Background"
          d="m234.2 111.8 53.3-21.3c5.5-2.2 11 3.2 8.9 8.8-1.8 4.6 1.7 9.5 6.7 9.3l30.9-1.5c6.3-.3 12.6 1.5 17.8 5.2 7.5 5.3 11.9 13.9 11.9 23.1v4.9c0 6.8 2.5 13.4 7.1 18.5l36.2 40.6c3 3.3 6.7 5.9 10.9 7.5l118.3 44.7c10.3 3.9 19.2 10.6 25.7 19.4l8.5 11.5c5.6 7.6 15.9 9.9 24.2 5.5 5.5-2.9 9.2-8.3 10-14.5l.6-4.4c.8-6.6 5.1-12.3 11.2-15.1l25.9-11.4c6.9-3.1 14.5-4.3 22.1-3.5l13.9 1.4c2.2.2 4.3-.5 5.9-2.1 1.9-1.9 4.8-2.6 7.4-1.8l58 18.8c6.3 2 13 2.5 19.6 1.2l2.7-.5c6.9-1.3 10.5-9.1 7-15.3-2.4-4.3-1.5-9.7 2.2-12.9l3.8-3.2c4-3.5 10-3.3 13.9.3l14.8 14c7.4 7 18.2 9 27.6 5.1l7.5-3.2c4.4-1.8 8-5.1 10.2-9.3 4.2-7.8 12.8-12.1 21.6-10.7l6 1c10.3 1.7 17.7 10.7 17.3 21.1v2.2c-.5 12 4.9 23.5 14.5 30.8l12.9 9.8c6.2 4.7 9.2 12.6 7.8 20.3l-.6 3.3c-1.4 7.2-6.5 13.2-13.5 15.7l-1.2.4c-4.9 1.7-9 5.3-11.4 9.9l-24.4 46.8c-3.5 6.8-9.9 11.6-17.4 13.2h-.3c-5.5 1.2-10.4 4.1-14.1 8.2-12.9 14.4-44.3 49.3-57.8 64.4s-7.7 10.2-10.2 16.1L778 507.9c-4 9.4-10.4 17.6-18.6 23.7l-26.8 20c-16.2 12.1-26.7 30.2-29.3 50.2v.5c-1.2 8.7-4.4 17-9.3 24.1-3.5 5-7.8 11.1-11.8 16.8-8.6 12.2-21.8 20.4-36.6 22.5l-11 1.5c-7 1-13.6 4.2-18.7 9.1l-4.2 4.1c-3.7 3.5-6.5 7.8-8.3 12.5l-4.1 10.9c-5.4 14.4-19.9 23.4-35.2 21.7l-40.7-4.3c-5.8-.6-11.3-2.7-16-6.1l-29.2-20.8c-8.1-5.7-13.2-14.7-14.1-24.6l-2-22.7c-.5-5.3-4.2-9.7-9.3-11l-38.2-9.7c-4.8-1.2-8.5-5.2-9.2-10.1l-.4-2.4c-1-6.6-7.1-11.2-13.8-10.4l-32.2 3.8c-2 .2-4-1-4.6-3l-13.1-42.1c-2.9-9.2-7.7-17.7-14.1-24.8l-71.5-79.4c-1.6-1.8-3.4-3.3-5.5-4.5l-51.1-31c-4.8-2.9-8.5-7.4-10.4-12.8l-3.3-9.4c-3.6-10.4.1-21.9 9.2-28.1l19-13.1c3.9-2.7 7-6.2 9.2-10.4l14.8-28.2c3.6-6.8 4.3-14.8 1.9-22.1l-7.2-22.7c-1.6-5.1 1.7-10.4 6.9-11.2l1.3-.2c5.8-.9 9.1-7.3 6.4-12.5l-24.4-47.8c-2-3.8-5.4-6.7-9.5-7.9-9.2-2.8-13.8-13.2-9.7-21.9l28.8-60.2c.5-1 1.3-1.7 2.3-2.1Z"
          style={{
            fill: '#f5f5f5',
          }}
        />
        <path
          id="Carte_svg__Sea"
          d="m0 0 62.2 50.5c1 .8 2.1 1.3 3.3 1.7L90 58.7c8.3 2.2 15.3 8 19 15.8l.9 2c4.4 9.3 13 15.9 23 17.9l10.9 2.1c14.8 2.9 28.7 9.4 40.3 19l9.6 7.8c4.4 3.6 10.9 1.3 12.2-4.2 1.2-5.3 6.2-8.8 11.6-8.2l15.1 1.7-.7 1.5-28.8 60.2c-4.2 8.7.4 19.1 9.7 21.9 4.1 1.2 7.5 4.1 9.5 7.9l24.6 48.1c2.6 5.1-.5 11.3-6.2 12.2l-1.8.3c-5.2.8-8.4 6-6.8 11l7.3 22.9c2.3 7.3 1.6 15.3-1.9 22.1l-14.8 28.2c-2.2 4.1-5.3 7.7-9.2 10.4l-16.2 11.2c-10.7 7.3-15.1 20.9-10.8 33.1l1.6 4.7c2.2 6.3 6.5 11.6 12.2 15.1l49.2 29.9c2.4 1.5 4.6 3.3 6.4 5.3l70.5 78.2c6.9 7.6 12 16.7 15.1 26.5l12.8 41.2 1 3.2v.4l4 17.8c.7 3 .1 6.1-1.5 8.6l-7.1 11.1c-3.1 4.8-10.4 3.9-12.2-1.6L328 611.4c-3.4-10.7-9.3-20.4-17.2-28.3l-74.2-74.8c-.9-.9-1.5-1.9-1.9-3l-3.6-9.5c-1.4-3.5-6.3-3.8-8-.4l-18.3 36.3c-.5.9-.7 1.9-.9 2.8l-35.1 285.6c-.3 2.2-2.1 3.9-4.4 3.9H0z"
          style={{
            fill: '#96c0e8',
          }}
        />
        <path
          id="Carte_svg__Sea-motif"
          d="m0 0 62.2 50.5c1 .8 2.1 1.3 3.3 1.7L90 58.7c8.3 2.2 15.3 8 19 15.8l.9 2c4.4 9.3 13 15.9 23 17.9l10.9 2.1c14.8 2.9 28.7 9.4 40.3 19l9.6 7.8c4.4 3.6 10.9 1.3 12.2-4.2 1.2-5.3 6.2-8.8 11.6-8.2l15.1 1.7-.7 1.5-28.8 60.2c-4.2 8.7.4 19.1 9.7 21.9 4.1 1.2 7.5 4.1 9.5 7.9l24.6 48.1c2.6 5.1-.5 11.3-6.2 12.2l-1.8.3c-5.2.8-8.4 6-6.8 11l7.3 22.9c2.3 7.3 1.6 15.3-1.9 22.1l-14.8 28.2c-2.2 4.1-5.3 7.7-9.2 10.4l-16.2 11.2c-10.7 7.3-15.1 20.9-10.8 33.1l1.6 4.7c2.2 6.3 6.5 11.6 12.2 15.1l49.2 29.9c2.4 1.5 4.6 3.3 6.4 5.3l70.5 78.2c6.9 7.6 12 16.7 15.1 26.5l12.8 41.2 1 3.2v.4l4 17.8c.7 3 .1 6.1-1.5 8.6l-7.1 11.1c-3.1 4.8-10.4 3.9-12.2-1.6L328 611.4c-3.4-10.7-9.3-20.4-17.2-28.3l-74.2-74.8c-.9-.9-1.5-1.9-1.9-3l-3.6-9.5c-1.4-3.5-6.3-3.8-8-.4l-18.3 36.3c-.5.9-.7 1.9-.9 2.8l-35.1 285.6c-.3 2.2-2.1 3.9-4.4 3.9H0V0Z"
          style={{
            strokeMiterlimit: 10,
            fill: 'url(#Carte_svg__Nouveau_motif_2)',
            opacity: 0.5,
            stroke: 'url(#Carte_svg__Nouveau_motif_2)',
          }}
        />
        <path
          id="Carte_svg__Ile-2"
          d="m144.8 254.9-10.7 16.3c-.9 1.4-.9 3.1-.1 4.5l46.2 78.7c1.7 3 6.1 2.8 7.6-.3l7.1-14.8c.4-.7.5-1.6.4-2.4l-6.9-57.8c-.2-1.3-.9-2.4-2-3.1l-35.7-22.4c-2-1.2-4.6-.7-5.9 1.3"
          className="Carte_svg__st14"
        />
        <path
          id="Carte_svg__Ile-1"
          d="m101.6 153.9 1.1 3c.7 2 2.8 3.2 5 2.8l8.5-1.8c1.3-.3 2.7 0 3.7.9l29.8 25.6a4.3 4.3 0 0 0 4.6.6l13.5-6.4c3.1-1.4 3.3-5.7.4-7.5l-38.3-23.9c-1-.6-2.2-.8-3.4-.5l-13.8 3.7c-1.4.4-3 0-4-1-3.3-3.1-8.5.3-7 4.5Z"
          className="Carte_svg__st14"
        />
      </g>
      <g id="Carte_svg__Traces-Velo">
        <path
          d="m239.6 270.6 11.6 5.1c6.1 2.7 10.2 8.7 10.1 15.3-.1 9.8 10.2 16.1 18.8 11.5l8-4.2c8.7-4.6 19 2.3 18.4 12.2.8 6.6 5.8 12.1 12.3 13.3 25.5 4.8 54.7 20.4 65.6 49.9 13.4 36.1 29.4 50.4 99 50.4H515c12.3-.1 24.1 4.7 32.8 13.5h0c8.7 8.7 20.4 13.5 32.7 13.5h136.4c53 0 103.9 21.1 141.4 58.6"
          className="Carte_svg__st6"
        />
        <path
          d="M393.5 123.7H290.1c-7.2 0-14.2 2.7-19.6 7.5l-31.1 27.6c-9.9 8.8-12.6 23.2-6.6 35l22.2 43.7c4.3 10.1 6.5 20.9 6.5 31.9v21.9c-.1 9.7 10.2 15.9 18.7 11.3l8-4.2c8.7-4.6 19 2.3 18.4 12.2h0c-.4 6.2-5.2 11.3-11.3 12.1-11.2 1.3-28.6 3.4-39.1 4.6s-9.4 4.3-10.8 9.2c-8.9 31.7-22.3 30.9-40.6 49.9-14.4 15 12.7 31.7 25.6 37.7 13.8 6.3 40.6 23.4 55 40.5 10.8 12.9 44.3 50.7 44.3 50.7.8.8 1.5 1.6 2.3 2.5 16.7 18.5 28 41.3 33.4 65.6l10.2 45.4.3 28.8c.2 15.5 12.8 28 28.3 28h6.8c9.3 0 18.3 3.7 24.9 10.3l24.6 24.6c8.2 8.2 12.9 19.4 12.9 31h0c0 11.6 4.6 22.8 12.9 31l41.1 41.1"
          className="Carte_svg__st6"
        />
        <path
          d="m261 139.7 10.2-9-13.5-7.4c-5.1-2.8-9.1-7.2-11.4-12.5h0c-4.8-11.1-16.5-17.4-28.4-15.4l-6.5 1.1c-7.9 1.4-16-.4-22.5-5.1l-10.1-7.2c-3.2-2.3-6.9-3.9-10.8-4.8l-16.8-3.8c-9.7-2.2-17.7-9-21.3-18.3h0c-3.9-9.9-12.8-17.1-23.3-18.7l-21.8-3.3c-4.8-.7-9.3-2.6-13.2-5.5L31 0M226.1 512.8l-6.8 13.1c-3.1 5.9-5 12.4-5.8 19.1l-29.8 278.9M717 451c49.1 0 88.9-39.8 88.9-88.9V171M646.9 451h-64.5c-24 0-43.4 19.5-43.4 43.4h0c0 11.5-4.6 22.6-12.7 30.7l-.9.9c-8.1 8.1-12.7 19.2-12.7 30.7v46.8c0 9.8-3.9 19.2-10.8 26.1h0c-6.9 6.9-10.8 16.3-10.8 26.1h0c0 16.3-13.2 29.5-29.5 29.5H411"
          className="Carte_svg__st6"
        />
        <path
          d="m566.2 226.1 18.6 11.3c5.6 3.4 8.6 10 7.4 16.5h0c-1.1 5.7 1.1 11.6 5.7 15.3l17.6 14.1c2.8 2.2 6.2 3.5 9.8 3.5l51.2.4c3.4 0 6.7 1.1 9.4 3.2l6.8 5.1c4.5 3.4 10.4 4.1 15.6 2l48.6-20.2c1.9-.8 4-1.2 6.1-1.2h27c8.8 0 15.9 7.1 15.9 15.9v95.7c0 8.8 7.1 15.9 15.9 15.9h71.4"
          className="Carte_svg__st6"
        />
      </g>
      <g id="Carte_svg__Villes">
        <g id="Villes">
          <path
            id="Vector_8"
            d="M245.901 206.5C246.101 198.6 246.401 190.6 246.601 182.7C281.201 182.4 315.901 182 350.501 181.7C350.501 186.4 350.301 191.1 350.201 195.8C350.101 200.5 350.201 198.5 350.101 200.9C350.001 203.3 350.101 203.3 349.901 204.7H344.601C343.101 204.7 340.401 204.7 338.201 204.8C334.001 204.8 329.701 205 325.501 205C308.501 205.3 291.501 205.6 274.501 205.9L245.801 206.4L245.901 206.5Z"
            fill="#21212B"
          />
          <path
            id="Vector_12"
            d="M384.2 369.3C386.7 369.3 388.8 370.6 388.8 373.6C388.8 376.6 387.7 378.1 384.2 378.1C380.7 378.1 380 375.8 380 373.9C380 372 381.7 369.4 384.2 369.4V369.3Z"
            fill="#21212B"
          />
          <path
            id="Vector_13"
            d="M436.001 265.5C438.501 265.5 440.601 266.8 440.601 269.8C440.601 272.8 439.501 274.3 436.001 274.3C432.501 274.3 431.801 272 431.801 270.1C431.801 268.2 433.501 265.6 436.001 265.6V265.5Z"
            fill="#21212B"
          />
          <path
            id="Vector_14"
            d="M681.501 282.7C684.001 282.7 686.101 284 686.101 287C686.101 290 685.001 291.5 681.501 291.5C678.001 291.5 677.301 289.2 677.301 287.3C677.301 285.4 679.001 282.8 681.501 282.8V282.7Z"
            fill="#21212B"
          />
          <path
            id="Vector_15"
            d="M805.6 306.3C808.1 306.3 810.2 307.6 810.2 310.6C810.2 313.6 809.1 315.1 805.6 315.1C802.1 315.1 801.4 312.8 801.4 310.9C801.4 309 803.1 306.4 805.6 306.4V306.3Z"
            fill="#21212B"
          />
          <path
            id="Vector_16"
            d="M720.901 394.7C723.401 394.7 725.501 396 725.501 399C725.501 402 724.401 403.5 720.901 403.5C717.401 403.5 716.701 401.2 716.701 399.3C716.701 397.4 718.401 394.8 720.901 394.8V394.7Z"
            fill="#21212B"
          />
          <path
            id="Vector_17"
            d="M646.8 446.7C649.3 446.7 651.4 448 651.4 451C651.4 454 650.3 455.5 646.8 455.5C643.3 455.5 642.6 453.2 642.6 451.3C642.6 449.4 644.3 446.8 646.8 446.8V446.7Z"
            fill="#21212B"
          />
          <path
            id="Vector_18"
            d="M595.701 487.1C595.901 479.2 596.201 471.2 596.401 463.3C630.401 463 664.201 462.6 698.201 462.3C698.201 467 698.001 471.7 697.901 476.4C697.801 481.1 697.901 479.1 697.901 481.5C697.901 483.9 697.901 483.9 697.701 485.3H692.501C690.401 485.3 688.401 485.3 686.301 485.4L673.901 485.6C657.201 485.9 640.601 486.2 623.901 486.5C614.501 486.7 605.201 486.8 595.801 487L595.701 487.1Z"
            fill="#21212B"
          />
          <path
            id="Vector_19"
            d="M614.001 590.1C616.501 590.1 618.601 591.4 618.601 594.4C618.601 597.4 617.501 598.9 614.001 598.9C610.501 598.9 609.801 596.6 609.801 594.7C609.801 592.8 611.501 590.2 614.001 590.2V590.1Z"
            fill="#21212B"
          />
          <path
            id="Vector_20"
            d="M440.2 540.5C442.7 540.5 444.8 541.8 444.8 544.8C444.8 547.8 443.7 549.3 440.2 549.3C436.7 549.3 436 547 436 545.1C436 543.2 437.7 540.6 440.2 540.6V540.5Z"
            fill="#21212B"
          />
          <path
            id="Vector_21"
            d="M285.901 460.6C288.401 460.6 290.501 461.9 290.501 464.9C290.501 467.9 289.401 469.4 285.901 469.4C282.401 469.4 281.701 467.1 281.701 465.2C281.701 463.3 283.401 460.7 285.901 460.7V460.6Z"
            fill="#21212B"
          />
          <path
            id="Vector_22"
            d="M483.2 419.5C485.7 419.5 487.8 420.8 487.8 423.8C487.8 426.8 486.7 428.3 483.2 428.3C479.7 428.3 479 426 479 424.1C479 422.2 480.7 419.6 483.2 419.6V419.5Z"
            fill="#21212B"
          />

          {citiesArray.map((city) => (
            <g id={city.id} key={city.id}>
              <a href={city.link} className="Carte_svg__city-link" key={city.id}>
                {Array.isArray(city.path) ? (
                  city.path.map((path, index) => (
                    <path
                      id={city.id}
                      key={`${city.id}-${index}`}
                      d={path}
                      fill={city.fill || '#21212B'}
                      className={`${city.className} `}
                    />
                  ))
                ) : (
                  <path
                    id={city.id}
                    key={city.id}
                    d={city.path}
                    fill={city.fill || '#21212B'}
                    className={`${city.className} `}
                  />
                )}
              </a>
            </g>
          ))}
        </g>
      </g>
      <g id="Carte_svg__Decors">
        <path
          d="M396.8 455.2c-1.1-.5-2.2-.4-3.2 0-1-.4-2.1-.5-3.2 0s-1.9 1.3-2.3 2.2c-1 .4-1.8 1.2-2.3 2.2s-.4 2.2 0 3.2c-.4 1-.5 2.1 0 3.2.4 1.1 1.3 1.9 2.2 2.3.4 1 1.2 1.8 2.2 2.3s2.2.4 3.2 0c1 .4 2.1.5 3.2 0 1.1-.4 1.9-1.3 2.3-2.2 1-.4 1.8-1.2 2.3-2.2s.4-2.2 0-3.2c.4-1 .5-2.1 0-3.2s-1.3-1.9-2.2-2.3c-.4-1-1.2-1.8-2.2-2.3"
          className="Carte_svg__st16"
        />
        <path
          d="M395 466h-2.9l-1 10c0 .2.2.4.4.4h4c.2 0 .4-.2.4-.4l-1-10Z"
          className="Carte_svg__st16"
        />
        <path
          d="M650.2 564.2c-1.1-.5-2.2-.4-3.2 0-1-.4-2.1-.5-3.2 0s-1.9 1.3-2.3 2.2c-1 .4-1.8 1.2-2.3 2.2s-.4 2.2 0 3.2c-.4 1-.5 2.1 0 3.2.4 1.1 1.3 1.9 2.2 2.3.4 1 1.2 1.8 2.2 2.3s2.2.4 3.2 0c1 .4 2.1.5 3.2 0 1.1-.4 1.9-1.3 2.3-2.2 1-.4 1.8-1.2 2.3-2.2s.4-2.2 0-3.2c.4-1 .5-2.1 0-3.2s-1.3-1.9-2.2-2.3c-.4-1-1.2-1.8-2.2-2.3"
          className="Carte_svg__st12"
        />
        <path
          d="M648.4 575h-2.9l-1 10c0 .2.2.4.4.4h4c.2 0 .4-.2.4-.4l-1-10ZM377.1 470.6c-1.1-.5-2.2-.4-3.2 0-1-.4-2.1-.5-3.2 0s-1.9 1.3-2.3 2.2c-1 .4-1.8 1.2-2.3 2.2s-.4 2.2 0 3.2c-.4 1-.5 2.1 0 3.2.4 1.1 1.3 1.9 2.2 2.3.4 1 1.2 1.8 2.2 2.3s2.2.4 3.2 0c1 .4 2.1.5 3.2 0 1.1-.4 1.9-1.3 2.3-2.2 1-.4 1.8-1.2 2.3-2.2s.4-2.2 0-3.2c.4-1 .5-2.1 0-3.2s-1.3-1.9-2.2-2.3c-.4-1-1.2-1.8-2.2-2.3"
          className="Carte_svg__st12"
        />
        <path
          d="M375.3 481.5h-2.9l-1 10c0 .2.2.4.4.4h4c.2 0 .4-.2.4-.4l-1-10Z"
          className="Carte_svg__st12"
        />
        <path
          d="M552.1 359.9c-1.1-.5-2.2-.4-3.2 0-1-.4-2.1-.5-3.2 0s-1.9 1.3-2.3 2.2c-1 .4-1.8 1.2-2.3 2.2s-.4 2.2 0 3.2c-.4 1-.5 2.1 0 3.2.4 1.1 1.3 1.9 2.2 2.3.4 1 1.2 1.8 2.2 2.3s2.2.4 3.2 0c1 .4 2.1.5 3.2 0 1.1-.4 1.9-1.3 2.3-2.2 1-.4 1.8-1.2 2.3-2.2s.4-2.2 0-3.2c.4-1 .5-2.1 0-3.2s-1.3-1.9-2.2-2.3c-.4-1-1.2-1.8-2.2-2.3"
          className="Carte_svg__st16"
        />
        <path
          d="M550.3 370.7h-2.9l-1 10c0 .2.2.4.4.4h4c.2 0 .4-.2.4-.4l-1-10Z"
          className="Carte_svg__st16"
        />
        <path
          d="M532.4 375.3c-1.1-.5-2.2-.4-3.2 0-1-.4-2.1-.5-3.2 0s-1.9 1.3-2.3 2.2c-1 .4-1.8 1.2-2.3 2.2s-.4 2.2 0 3.2c-.4 1-.5 2.1 0 3.2.4 1.1 1.3 1.9 2.2 2.3.4 1 1.2 1.8 2.2 2.3s2.2.4 3.2 0c1 .4 2.1.5 3.2 0 1.1-.4 1.9-1.3 2.3-2.2 1-.4 1.8-1.2 2.3-2.2s.4-2.2 0-3.2c.4-1 .5-2.1 0-3.2s-1.3-1.9-2.2-2.3c-.4-1-1.2-1.8-2.2-2.3"
          className="Carte_svg__st12"
        />
        <path
          d="M530.6 386.1h-2.9l-1 10c0 .2.2.4.4.4h4c.2 0 .4-.2.4-.4l-1-10ZM621 301.2c-1.1-.5-2.2-.4-3.2 0-1-.4-2.1-.5-3.2 0s-1.9 1.3-2.3 2.2c-1 .4-1.8 1.2-2.3 2.2s-.4 2.2 0 3.2c-.4 1-.5 2.1 0 3.2.4 1.1 1.3 1.9 2.2 2.3.4 1 1.2 1.8 2.2 2.3s2.2.4 3.2 0c1 .4 2.1.5 3.2 0 1.1-.4 1.9-1.3 2.3-2.2 1-.4 1.8-1.2 2.3-2.2s.4-2.2 0-3.2c.4-1 .5-2.1 0-3.2s-1.3-1.9-2.2-2.3c-.4-1-1.2-1.8-2.2-2.3"
          className="Carte_svg__st12"
        />
        <path
          d="M619.2 312.1h-2.9l-1 10c0 .2.2.4.4.4h4c.2 0 .4-.2.4-.4l-1-10Z"
          className="Carte_svg__st12"
        />
        <path
          d="M642.1 322.9c-1.1-.5-2.2-.4-3.2 0-1-.4-2.1-.5-3.2 0s-1.9 1.3-2.3 2.2c-1 .4-1.8 1.2-2.3 2.2s-.4 2.2 0 3.2c-.4 1-.5 2.1 0 3.2.4 1.1 1.3 1.9 2.2 2.3.4 1 1.2 1.8 2.2 2.3s2.2.4 3.2 0c1 .4 2.1.5 3.2 0 1.1-.4 1.9-1.3 2.3-2.2 1-.4 1.8-1.2 2.3-2.2s.4-2.2 0-3.2c.4-1 .5-2.1 0-3.2s-1.3-1.9-2.2-2.3c-.4-1-1.2-1.8-2.2-2.3"
          className="Carte_svg__st16"
        />
        <path
          d="M640.3 333.7h-2.9l-1 10c0 .2.2.4.4.4h4c.2 0 .4-.2.4-.4l-1-10Z"
          className="Carte_svg__st16"
        />
        <path d="m316.4 405.8-7.8-21.3-7.8 21.3h5.8v7.8h4v-7.8z" className="Carte_svg__st12" />
        <path d="m675.1 557.8-7.8-21.3-7.7 21.3h5.8v7.8h3.9v-7.8z" className="Carte_svg__st16" />
        <path
          d="m866 370.3-7.7-21.3-7.8 21.3h5.8v7.7h3.9v-7.7zM615.3 530.4l-7.7-21.3-7.8 21.3h5.8v7.7h4v-7.7zM397.9 237.6l-7.8-21.3-7.7 21.3h5.7v7.8h4v-7.8z"
          className="Carte_svg__st12"
        />
        <path d="m294.4 398.5-7.7-21.3-7.8 21.3h5.8v7.8h3.9v-7.8z" className="Carte_svg__st16" />
        <path
          d="m579.4 403.5-11.4 1v-10.4l11.4-1.1zM579.3 392.5l-11.4 1-4.6-7.6 11.4-1zM567.3 393.9l-4.7-7.6-5.7 6.3v11.5h1.8v-6.7c0-1.6 1.3-2.9 2.9-2.9s2.9 1.3 2.9 2.9v7h2.9v-10.5ZM370.7 249.5l-11.4 1V240l11.4-1zM370.7 238.5l-11.4 1-4.7-7.6 11.4-1zM358.6 239.9l-4.7-7.6-5.7 6.3v11.5h1.8v-6.7c0-1.6 1.3-2.9 2.9-2.9s2.9 1.3 2.9 2.9v7h2.9v-10.5ZM552 679.9l-11.4 1.1v-10.5l11.4-1zM552 668.9l-11.5 1.1-4.6-7.6 11.4-1.1zM539.9 670.3l-4.7-7.6-5.7 6.3v11.5h1.8v-6.7c0-1.6 1.3-2.9 2.9-2.9s2.9 1.3 2.9 2.9v7h2.9v-10.5ZM431.3 491.4l-11.4 1.1V482l11.4-1zM431.2 480.4l-11.4 1.1-4.6-7.6 11.4-1.1zM419.2 481.8l-4.7-7.6-5.7 6.3V492h1.8v-6.7c0-1.6 1.3-2.9 2.9-2.9s2.9 1.3 2.9 2.9v7h2.9v-10.5Z"
          className="Carte_svg__st23"
        />
      </g>
      <g id="Carte_svg__FLOWVELO_-_OVER">
        <path
          d="M284.9 295.3c2.5 0 4.6 1.3 4.6 4.3s-1.1 4.5-4.6 4.5-4.2-2.3-4.2-4.2 1.7-4.5 4.2-4.5ZM384.2 369.3c2.5 0 4.6 1.3 4.6 4.3s-1.1 4.5-4.6 4.5-4.2-2.3-4.2-4.2 1.7-4.5 4.2-4.5ZM646.8 446.7c2.5 0 4.6 1.3 4.6 4.3s-1.1 4.5-4.6 4.5-4.2-2.3-4.2-4.2 1.7-4.5 4.2-4.5ZM483.2 419.5c2.5 0 4.6 1.3 4.6 4.3s-1.1 4.5-4.6 4.5-4.2-2.3-4.2-4.2 1.7-4.5 4.2-4.5Z"
          className="Carte_svg__st13"
        />
        <path
          d="m239.6 270.6 11.6 5.1c6.1 2.7 10.2 8.7 10.1 15.3-.1 9.8 10.2 16.1 18.8 11.5l8-4.2c8.7-4.6 19 2.3 18.4 12.2.8 6.6 5.8 12.1 12.3 13.3 25.5 4.8 54.7 20.4 65.6 49.9 13.4 36.1 29.4 50.4 99 50.4H515c12.3-.1 24.1 4.7 32.8 13.5h0c8.7 8.7 20.4 13.5 32.7 13.5h136.4c28.2 0 55.7 5.9 81 17.1"
          style={{
            fill: 'none',
            strokeWidth: 2,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            stroke: strokeStyles['flowvelo'].stroke,
          }}
        />
      </g>
      <g id="Carte_svg__TRANSIBERIQUE_-_OVER">
        <path
          d="M805.6 306.3c2.5 0 4.6 1.3 4.6 4.3s-1.1 4.5-4.6 4.5-4.2-2.3-4.2-4.2 1.7-4.5 4.2-4.5ZM646.8 446.7c2.5 0 4.6 1.3 4.6 4.3s-1.1 4.5-4.6 4.5-4.2-2.3-4.2-4.2 1.7-4.5 4.2-4.5Z"
          className="Carte_svg__st19"
        />
        <path
          d="M805.9 232v130.1c0 49.1-39.8 88.9-88.9 88.9h0-134.5c-24 0-43.4 19.5-43.4 43.4h0c0 11.5-4.6 22.6-12.7 30.7l-.9.9c-8.1 8.1-12.7 19.2-12.7 30.7v46.8c0 9.8-3.9 19.2-10.8 26.1h0c-6.9 6.9-10.8 16.3-10.8 26.1h0c0 13.9-9.6 25.5-22.5 28.7"
          style={{
            fill: 'none',
            strokeWidth: 2,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            stroke: strokeStyles['scandiberique'].stroke,
          }}
        />
      </g>
      <g id="Carte_svg__VELODYSSEE_-_OVER">
        <path
          d="M285.9 460.6c2.5 0 4.6 1.3 4.6 4.3s-1.1 4.5-4.6 4.5-4.2-2.3-4.2-4.2 1.7-4.5 4.2-4.5ZM284.9 295.3c2.5 0 4.6 1.3 4.6 4.3s-1.1 4.5-4.6 4.5-4.2-2.3-4.2-4.2 1.7-4.5 4.2-4.5ZM242.3 341.1c2.5 0 4.6 1.3 4.6 4.3s-1.1 4.5-4.6 4.5-4.2-2.3-4.2-4.2 1.7-4.5 4.2-4.5ZM233.6 191.1c2.5 0 4.6 1.3 4.6 4.3s-1.1 4.5-4.6 4.5-4.2-2.3-4.2-4.2 1.7-4.5 4.2-4.5Z"
          className="Carte_svg__st24"
        />
        <path
          d="M244.7 107.6q.9 1.5 1.5 3h0c2.3 5.3 6.3 9.8 11.4 12.5l13.5 7.4-.7.6-31.1 27.6c-9.9 8.8-12.6 23.2-6.6 35l1.2 2.4 21 41.3c4.3 10.1 6.5 20.9 6.5 31.9v21.9c-.1 9.7 10.2 15.9 18.7 11.3l8-4.2c8.7-4.6 19 2.3 18.4 12.2h0c-.4 6.2-5.2 11.3-11.3 12.1-11.2 1.3-28.6 3.3-39 4.6-5.2.6-9.4 4.3-10.8 9.3-8.9 31.7-22.3 30.9-40.6 49.9-14.4 15 12.7 31.7 25.6 37.7 13.8 6.3 40.6 23.4 55 40.5"
          style={{
            fill: 'none',
            strokeWidth: 2,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            stroke: strokeStyles['velodyssee'].stroke,
          }}
        />
        <path
          d="M271 126.3c2.5 0 4.6 1.3 4.6 4.3s-1.1 4.5-4.6 4.5-4.2-2.3-4.2-4.2 1.7-4.5 4.2-4.5Z"
          className="Carte_svg__st24"
        />
      </g>
      <g id="Carte_svg__CANAL2MER_-_OVER">
        <path
          d="M285.4 464.4c10.8 12.9 44.3 50.7 44.3 50.7.8.8 1.5 1.6 2.3 2.5 16.7 18.5 28 41.3 33.4 65.6l5.1 22.8"
          style={{
            fill: 'none',
            strokeWidth: 2,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            stroke: strokeStyles['canal2mer'].stroke,
          }}
        />
        <path
          d="M285.9 460.6c2.5 0 4.6 1.3 4.6 4.3s-1.1 4.5-4.6 4.5-4.2-2.3-4.2-4.2 1.7-4.5 4.2-4.5Z"
          style={{
            fill: '#002684',
          }}
        />
      </g>
      <g id="Carte_svg__FRANCETTE_-_OVER">
        <path
          d="m233.8 195.7-.9-1.9c-6-11.8-3.3-26.2 6.6-35l31.1-27.6c5.4-4.8 12.4-7.5 19.6-7.5h70.9"
          style={{
            fill: 'none',
            stroke: strokeStyles['francette'].stroke,
            strokeWidth: 2,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
        />
        <path
          d="M271 126.3c2.5 0 4.6 1.3 4.6 4.3s-1.1 4.5-4.6 4.5-4.2-2.3-4.2-4.2 1.7-4.5 4.2-4.5ZM233.6 191.1c2.5 0 4.6 1.3 4.6 4.3s-1.1 4.5-4.6 4.5-4.2-2.3-4.2-4.2 1.7-4.5 4.2-4.5Z"
          className="Carte_svg__st8"
        />
      </g>
      <g id="Carte_svg__VELIDEALE_-_OVER">
        <path
          d="m605 274.9 10.4 8.3c2.8 2.2 6.2 3.5 9.8 3.5l51.2.4c3.4 0 6.7 1.1 9.4 3.2l6.8 5.1c4.5 3.4 10.4 4.1 15.6 2l48.6-20.2c1.9-.8 4-1.2 6.1-1.2h27c8.8 0 15.9 7.1 15.9 15.9v95.7c0 8.8 7.1 15.9 15.9 15.9h33.5"
          style={{
            fill: 'none',
            stroke: strokeStyles['velideale'].stroke,
            strokeWidth: 2,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
        />
        <path
          d="M681.5 282.7c2.5 0 4.6 1.3 4.6 4.3s-1.1 4.5-4.6 4.5-4.2-2.3-4.2-4.2 1.7-4.5 4.2-4.5ZM805.6 306.3c2.5 0 4.6 1.3 4.6 4.3s-1.1 4.5-4.6 4.5-4.2-2.3-4.2-4.2 1.7-4.5 4.2-4.5Z"
          className="Carte_svg__st13"
        />
      </g>
    </svg>
  );
};

export default SvgCarteOn;
