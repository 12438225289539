import { Loader } from '@googlemaps/js-api-loader';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DROM, INTERNATIONAL_AGENT_ID } from '../../tools/Constants';
import { useTranslation } from '../../tools/i18n';
import { Button } from '../Button';
import Carousel from '../Carousel/Carousel';
import { Picto } from '../Picto';
import { ResponsiveImage } from '../ResponsiveImage';
import { FilterLayout } from './FiltersLayout';
import { ChatBotComponentProps } from './index';
import ReactSelect from 'react-select';
import { TravelPlannerSearchMapType } from './types';

import StaticCarousel from '../Carousel/StaticCarousel';
type AutocompletePrediction = google.maps.places.AutocompletePrediction;
type AutocompleteService = google.maps.places.AutocompleteService;

interface SearchMapProps extends ChatBotComponentProps {
  step: TravelPlannerSearchMapType;
  siteConfiguration: any;

}

interface MultiId {
  label: string;
  value: string;
  coordinates?: {lat: number, lon: number}
  place_id?: string;
  postalCode?: string;
}

interface GetCoordinates {
  name: string;
  lat: number;
  lon: number;
  postalCode?: string;
}

 export const SearchMap = (props: SearchMapProps) => {
  const withSVG = props.siteConfiguration?.withSVG ?? false;
  const { t } = useTranslation();
  const { config, step, requirements, callback, stateData, setStateData } = props;
  const voidRef = useRef<HTMLDivElement>();
  const sessionToken = useRef(null);

  const { desktopMap, mobileMap } = step;
  const mapRef = useRef<HTMLDivElement>(null);
  const mobileMapRef = useRef<HTMLDivElement>(null);
  const [selectedPathId, setSelectedPathId] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>();
  const [predictions, setPredictions] = useState<any[]>([]);
  const [multiId, setMultiId] = useState<MultiId[]>([]);
  const autocomplete = useRef<AutocompleteService>();
  const { agentId } = props;
  const populars = useMemo(() => {
    if (!step.populars?.length) return [];
    return step?.populars?.sort(() => Math.random() - 0.5);
  }, [step]);

  const mostLiked = useMemo(() => {
    if (!step.mostLiked?.slides?.length) return [];
    return step?.mostLiked?.slides?.sort(() => Math.random() - 0.5);
  }, [step]);

  const translateRegionNames = useCallback(
    (svgString: string) => {
      const regionNames = [
        'bordeaux',
        'paris',
        'amazonie',
        'martinique',
        'saintmartin',
        'mayotte',
        'guyane_1',
        'guyane_2',
        'alsace',
        'bretagne',
        'normandie',
        'pays_loire',
        'occitanie',
        'pyrenees',
        'val_loire',
        'champagne',
        'bourgogne',
        'alpes_montblanc',
        'auvergne',
        'provence',
        'cote_azur',
        'alpes_sud',
        'corse',
        'biarritz',
        'guadeloupe_1',
        'guadeloupe_2',
        'guadeloupe_3',
        'saintmartin',
        'barthelemy_1',
        'barthelemy_2',
        'reunion_1',
        'reunion_2',
        'mayotte',
        'tahiti_1',
        'tahiti_2',
        'tahiti_3',
        'caledonie_1',
        'caledonie_2',
        'wallis_et_futuna_1',
        'wallis_et_futuna_2',
        'wallis_et_futuna_3',
        'saint_pierre_miquelon_1',
        'saint_pierre_miquelon_2',
        'jura',
        'haut_france',
      ];

      // Utiliser une expression régulière pour trouver les balises <text> avec leurs attributs
      const textTagRegex = /<text([^>]*)>(.*?)<\/text>/g;

      return svgString.replace(textTagRegex, (match, attributes, textContent) => {
        const translatedContent = regionNames.reduce((acc, region) => {
          const regionKey = `travelPlaner.Search.regions.${region}`;
          const translatedRegion = t(regionKey);
          return acc.replace(region, translatedRegion);
        }, textContent);

        if (translatedContent.trim() === ' ') {
          return ''; // Supprimer la balise <text> si la traduction est vide
        }
        return `<text${attributes}>${translatedContent}</text>`;
      });
    },
    [t]
  );

  const mobileMapTranslated = useMemo(
    () => translateRegionNames(mobileMap),
    [mobileMap, translateRegionNames]
  );
  const desktopMapTranslated = useMemo(
    () => translateRegionNames(desktopMap),
    [desktopMap, translateRegionNames]
  );

   useEffect(() => {
     const mapPaths = mapRef.current?.querySelectorAll('.selectable-path');
     mapPaths.forEach((m) => {
         const elementId = m.getAttribute("id").replace(/mobile_/g, '')
         if (multiId.some( item => item.value === elementId)){
           m.classList.add('selected')
         } else {
           m.classList.remove('selected')
         }
       }
     )

   }, [multiId]);

  useEffect(() => {
    if (typeof window !== 'undefined' && !window.google) {
      const loader = new Loader({
        apiKey: 'AIzaSyCYp4mEcSL6CN2IjfVnVjnJDdWCWeGhUz8',
        version: 'weekly',
        libraries: ['places'],
      });

      loader.load().catch(error => {
        console.error('Erreur lors du chargement de Google Maps:', error);
      });
    }
  }, []);

  const handleSelect = useCallback(
    async (pathId?: string, multiPathId?: string[]) => {
      if (typeof multiPathId !== "undefined") {
        const temp = { ...stateData };
        temp.searchMap = multiId.map(item => ({
          label: item.label.replace(/\n/g, ' ').replace(/\s+/g, ' '),
          value: item.value,
          coordinates: item.coordinates,
          postalCode: item.postalCode
        }));
        console.log("Données à envoyer (multi-destination):", temp);
        setStateData(temp);
        callback(temp);
        return;
      }
      const searchMapData = {
        ...stateData,
        searchMap: multiId.map(item => ({
          ...item,
          label: item.label.replace(/\n/g, ' ').replace(/\s+/g, ' ')
        }))
      };

      setStateData(searchMapData)

      const place = config.favorites?.find((f) => f.key === pathId?.replace(/mobile_/g, ''));
      if (!place?.label) return;
      const temp = { ...stateData };
      temp.lon = place.lng.toString();
      temp.lat = place.lat.toString();
      temp.cityKey = place.key;
      temp.cityLabel = place.label;
      temp.radius = place.radius;
      temp.selectedFavorite = true;
      temp.radius = place.radius;
      temp.searchMap=[{
        label: place.label,
        value: place.key,
        coordinates: {
          lat: place.lat,
          lon: place.lng,
        },
      }]
      setStateData(temp);
      callback(temp);
    },
    [config.favorites, stateData, setStateData, callback, multiId]
  );

  const handleClickPath = useCallback(
    (e: any) => {
      const id = e.target.id || e.target.parentNode.id;
      const label = e.target.parentNode.textContent.trim()
      if (step.multiDestination) {
        const tempId = id.replace(/mobile_/g, '');
        const newMultiId = [...multiId];
        if (newMultiId.some(item => item.value === tempId)) {
          const indexToRemove = newMultiId.indexOf(newMultiId.find(item => item.value === tempId));
          newMultiId.splice(indexToRemove, 1);
        } else {
          const favoriteCoordinates = config.favorites?.find((f) => f.key === tempId)
          newMultiId.push({label:label, value:tempId, coordinates:{lat: favoriteCoordinates.lat || 0, lon: favoriteCoordinates.lng || 0} });

        }
        setMultiId(newMultiId)
        return
      }
      if (id === undefined) return;
      if (!step.navOnClick && !step.multiDestination  ) {
        const activeMapPath = mapRef.current?.querySelector('.active');
        const mapPaths = mapRef.current?.querySelectorAll('.selectable-path');
        if (activeMapPath?.id === id) {
          activeMapPath.classList.remove('active');
          setSelectedPathId('');
          mapPaths.forEach((p) => p.classList.remove('inactive'));
          return;
        }
        if (activeMapPath) activeMapPath.classList.remove('active');
        mapPaths.forEach((p) => p.id !== e.target.id && p.classList.add('inactive'));
        e.target.classList.remove('inactive');
        e.target.classList.add('active');
        setSelectedPathId(id);
      } else if (step.navOnClick && !step.multiDestination) {
        handleSelect(id).catch((e) => console.error(e));
      }
    },
    [step.multiDestination, step.navOnClick, multiId, config.favorites, handleSelect]
  );

  useEffect(() => {
    if (!mapRef.current && !mobileMapRef.current) return;
    const mapPaths = [];
    mapPaths.push(
      ...(mapRef.current?.querySelectorAll('.selectable-path') as unknown as Element[])
    );
    mapPaths.push(
      ...(mobileMapRef.current?.querySelectorAll('.selectable-path') as unknown as Element[])
    );
    if (!mapPaths) return;
    mapPaths.forEach((p) => {
      p.addEventListener('mousedown', handleClickPath);
    });
    return () => {
      mapPaths.forEach((p) => {
        p.removeEventListener('mousedown', handleClickPath);
      });
    };
  }, [handleClickPath]);

  const subtitleKey = `travelPlaner.Search.subTitle`;
  const hasSubtitle = t(subtitleKey) !== subtitleKey;

  const autoCompleteOption = {
    ...(agentId !== INTERNATIONAL_AGENT_ID
      ? { componentRestrictions: { country: ['fr', ...DROM] } }
      : {}),
    fields: ['address_components', 'geometry', 'name'],
    types: ['locality', 'postal_code', 'colloquial_area'],
    sessionToken: sessionToken.current,
  };

  const getPredictions = async (input: string) => {
    try {
      if (!autocomplete.current) return { results: [], input };
      
      let r = await autocomplete.current.getPlacePredictions({
        input,
        ...autoCompleteOption,
      });
      let results: AutocompletePrediction[] = r?.predictions || [];
      return {
        results,
        input,
      };
    } catch (error) {
      console.error('Erreur lors de la récupération des prédictions:', error);
      return { results: [], input };
    }
  };

  const handleInput = (e) => {
    const value = typeof e === "string" ? e : e.target.value;
    if (!value) {
      setPredictions([]);
      return;
    }
    if (!autocomplete.current) {
      if (typeof window !== 'undefined' && window.google) {
        autocomplete.current = new google.maps.places.AutocompleteService();
        sessionToken.current = new google.maps.places.AutocompleteSessionToken();
      } else {
        return;
      }
    }
    getPredictions(value).then((r) => {
      if (!step.multiDestination && r.input !== inputRef.current?.value) return;
      setPredictions(r.results);
    });
  };

  const getPostalCodeFromOpenStreetMap = async (lat: number, lon: number): Promise<string | null> => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`,
        {
          headers: {
            'Accept-Language': 'fr'
          }
        }
      );
      const data = await response.json();
      return data.address?.postcode || null;
    } catch (error) {
      console.error('Erreur lors de la récupération du code postal OpenStreetMap:', error);
      return null;
    }
  };

  const getCoordinates = async (placeId: string): Promise<GetCoordinates> => {
    try {
      if (!voidRef.current) {
        console.warn('voidRef non initialisé');
        return {
          name: '',
          lat: 0,
          lon: 0,
        };
      }

      return new Promise((resolve, reject) => {
        const service = new google.maps.places.PlacesService(voidRef.current);
        service.getDetails(
          {
            placeId,
            fields: [...autoCompleteOption.fields, 'address_components'],
            sessionToken: autoCompleteOption.sessionToken,
          },
          async (result, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK && result) {
              const lat = result.geometry?.location.lat() || 0;
              const lon = result.geometry?.location.lng() || 0;
              
              // On utilise directement OpenStreetMap pour le code postal
              const postalCode = await getPostalCodeFromOpenStreetMap(lat, lon);
              console.log('Code postal OpenStreetMap:', postalCode);

              resolve({
                name: result.name || '',
                lat,
                lon,
                postalCode
              });
            } else {
              reject(`Failed to fetch coordinates: ${status}`);
            }
          }
        );
      });
    } catch (error) {
      console.error('Erreur lors de la récupération des coordonnées:', error);
      return {
        name: '',
        lat: 0,
        lon: 0,
      };
    }
  };

  const handleSelectPrediction = async (p: any) => {
    const coordinates = await getCoordinates(p.place_id);
        
    const temp = { ...stateData };
    temp.coordinates = [{
      lat: coordinates.lat,
      lon: coordinates.lon,
    }];
    
    temp.searchMap = {
      label: coordinates.name,
      value: coordinates.name,
      coordinates: {
        lat: coordinates.lat,
        lon: coordinates.lon
      },
      postalCode: coordinates.postalCode
    };
        
    setStateData(temp);
    callback(temp);
  };

  const adjustRectToText = useCallback(
    (textElement: SVGTextElement, rectElement: SVGRectElement) => {
      const bbox = textElement.getBBox();
      const padding = 10;

      const currentX = parseFloat(rectElement.getAttribute('x') || '0');
      const currentY = parseFloat(rectElement.getAttribute('y') || '0');

      const newX = bbox.x - padding;
      const newY = bbox.y - padding;

      rectElement.setAttribute('x', newX >= 0 ? newX.toString() : currentX.toString());
      rectElement.setAttribute('y', newY >= 0 ? newY.toString() : currentY.toString());
      rectElement.setAttribute('width', (bbox.width + padding * 2).toString());
      rectElement.setAttribute('height', (bbox.height + padding * 2).toString());
    },
    []
  );

  const alignTextsInGroup = useCallback((group: Element) => {
    const texts = group.querySelectorAll('text');
    if (texts.length <= 1) return;

    // Get x coordinate of first text
    const firstX = texts[0].getAttribute('x');

    // Apply same x coordinate to other texts
    for (let i = 1; i < texts.length; i++) {
      texts[i].setAttribute('x', firstX);
    }
  }, []);

  const adjustAllRectangles = useCallback(() => {
    if (!mapRef.current && !mobileMapRef.current) return;

    // Adjust for desktop map
    if (mapRef.current) {
      const groups = mapRef.current.querySelectorAll('g');
      groups.forEach((group) => {
        // Align texts in group first
        alignTextsInGroup(group);

        const texts = group.querySelectorAll('text');
        texts.forEach((text) => {
          const rect = group.querySelector('rect');
          if (rect) {
            adjustRectToText(text as SVGTextElement, rect as SVGRectElement);
          }
        });
      });
    }

    // Adjust for mobile map
    if (mobileMapRef.current) {
      const groups = mobileMapRef.current.querySelectorAll('g');
      groups.forEach((group) => {
        // Align texts in group first
        alignTextsInGroup(group);

        const texts = group.querySelectorAll('text');
        texts.forEach((text) => {
          const rect = group.querySelector('rect');
          if (rect) {
            adjustRectToText(text as SVGTextElement, rect as SVGRectElement);
          }
        });
      });
    }
  }, [adjustRectToText, alignTextsInGroup]);

  // Adjust rectangles on initial mount
  useEffect(() => {
    adjustAllRectangles();
  }, [adjustAllRectangles]);

  // Adjust rectangles when language changes
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      adjustAllRectangles();
    }, 50);

    return () => clearTimeout(timeoutId);
  }, [t, adjustAllRectangles]);

  if (!desktopMap || !mobileMap) return null;

   const customComponents = {
     DropdownIndicator: () => null, // Disable the DropdownIndicator
   };

   const handleMultiIdChange = async (selectedOptions: any[], action: any) => {
     switch (action.action) {
       case "remove-value":
         setMultiId(multiId.filter((item) => item.value !== action.removedValue.value));
         break;

       case "clear":
         setMultiId([]);
         break;

       default:
         let updatedMultiId: MultiId[] = [...multiId];
         for (const option of selectedOptions) {
           if(option.place_id){
             const coordinates = await getCoordinates(option.place_id);
             updatedMultiId.push({
               value: option.label,
               label: option.label,
               coordinates: {
                 lat: coordinates.lat,
                 lon: coordinates.lon,
               },
               postalCode: coordinates.postalCode
             });
           }
         }
         setMultiId(updatedMultiId);
     }
   };

   const mapPredictionToOption = (option: any) => ({
     value: option.description,
     label: option.description || option.name,
     place_id: option.place_id,
   });

   const fetchWithCORS = async (url: string) => {
     try {
       const response = await fetch(url, {
         credentials: 'include', // Pour gérer les cookies si nécessaire
         headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json'
         }
       });
       if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
       return await response.json();
     } catch (error) {
       console.error('Erreur de fetch:', error);
       throw error;
     }
   };

   return (
    <div className="searchMap">
      <FilterLayout
        config={config}
        title={t(`travelPlaner.Search.title`)}
        requirements={requirements}
        siteConfiguration={props.siteConfiguration}
        callback={step.multiDestination && (multiId?.length || 0) > 0 ? () => handleSelect(undefined, multiId.map(item => item.label.replace(/\n/g, ' ').replace(/\s+/g, ' '))) : undefined}
      >
        {hasSubtitle ? <div className="layoutSubtitle">{t(subtitleKey)}</div> : null}
        {step.showInput ? step.multiDestination  ?  (
          <div className="multi-destination-input">
          <ReactSelect
            components={customComponents}
            value={multiId}
            isMulti
            onChange={handleMultiIdChange}
            placeholder={t('travelPlaner.Search.input')}
            className="inputBlock"
            classNamePrefix="react-select"
            options={predictions.map(mapPredictionToOption)}
            isSearchable
            onInputChange={handleInput}
          />
            <div className="picto">
            <Picto iconKey="magnifyingGlass"/>
            </div>
          </div>
        ) : (
          <div className="searchContainer">
              <div className="inputBlock">
              <Picto iconKey={step.pictoVersion ? `search-${step.pictoVersion}` : 'search'} />
              <input
                ref={ref => { inputRef.current = ref }}
                onChange={(e) => handleInput(e)}
                placeholder={t('travelPlaner.Search.input')}
                />
            </div>
            <div className="predictions">
              {predictions.map((p, i) => (
                <button key={`prediction_${i}`} onClick={() => handleSelectPrediction(p)}>
                  <div className="prediction">
                    <Picto iconKey={'place'} />
                    {p.label || p.description}
                  </div>
                </button>
              ))}
            </div>
          </div>
        ) : undefined}
        <div
          className="map"
          dangerouslySetInnerHTML={{ __html: desktopMapTranslated }}
          ref={mapRef}
        />
        <div
          className="mobileMap"
          dangerouslySetInnerHTML={{ __html: mobileMapTranslated }}
          ref={mobileMapRef}
        />
        {step.hasCarousel && mostLiked.length ? (
          <StaticCarousel
            label={t('travelPlaner.MostLiked.title')}
            subLabel=""
            slides={mostLiked}
            className="searchMapCarousel"
            requirements={requirements}
            button={{
              ...step.mostLiked.button,
              text: t('travelPlaner.MostLiked.button'),
            }}
          />
        ) : undefined}
        {step.hasCarousel && populars.length ? (
          <Carousel label={t('travelPlaner.Popular.title')} slides={populars}>
            {populars.map((p) => (
              <div key={p.key} className="tiles" onClick={() => handleSelect(p.key)}>
                <ResponsiveImage
                  requirements={requirements}
                  className="carouselImage"
                  src={p.image}
                  alt={p.label}
                />
                <div className="carouselLabel">{p.label}</div>
              </div>
            ))}
          </Carousel>
        ) : undefined}
        {step.navOnClick ? undefined : (
          <Button
            onClick={() => handleSelect(selectedPathId)}
            withPicto={'caret-right'}
            textLabel="travelPlaner.Search.start"
            withSVG={withSVG}
            disabled={!selectedPathId}
          ></Button>
        )}
        {step.randomSearch && (
          <button type="button" onClick={() => handleSelect('all')}>
            {t(`travelPlaner.Search.random`)}
            <Picto iconKey={'caret-right'} />
          </button>
        )}
        <div nonce="__nonce__" style={{ display: 'none' }} ref={ref => { voidRef.current = ref }} />
      </FilterLayout>
    </div>
  );
};
