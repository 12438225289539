import { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { useContext } from 'react';
import CustomImage from '../../CustomImage';
import { Picto } from '../../Picto';
import { ResponsiveImage } from '../../ResponsiveImage';
import { TravelPlanerResultsContext } from '../TravelPlanerResultsContext';
import { Item } from './index';

interface Props {
  overlay?: boolean;
  item: Item;
  handleDeleteItem(id: UniqueIdentifier): void;
}

export default function PopupItemContainer(props: Props) {
  const { overlay, item, handleDeleteItem } = props;
  const { config, requirements } = useContext(TravelPlanerResultsContext);

  const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({
    id: item.id,
    data: {
      type: 'item',
      item,
    },
  });

  const style = {
    transform: CSS.Transform.toString(transform && {
      ...transform,
      scaleX: 1,
      scaleY: 1
    }),
    transition,
    touchAction: 'none',
    cursor: isDragging ? 'grabbing' : 'grab'
  };

  return (
    <div
      className={`PopupDayItem ${isDragging ? 'dragging' : ''} ${overlay ? 'overlay' : ''}`}
      ref={setNodeRef}
      nonce="__nonce__"
      style={style}
      {...attributes}
      {...listeners}
    >
      <button className="deleteButton" onClick={() => handleDeleteItem(item.id)}>
        <div className="deleteButtonContent">
          <Picto iconKey="minus" width="6" height="6" />
        </div>
      </button>
      <div className="popupDayItemContent">
        <div className="popupDayItemImage">
          {item.data.urlimg || item.data.tca_categ ? (
            <ResponsiveImage
              requirements={requirements}
              notLazy
              src={item.data.urlimg}
              alt={item.data.urlimg || item.data.tca_categ}
              fallback={`https://tourismebyca.twic.pics/static/${requirements.partner}/v2/etablissement/${item.data.tca_categ}.jpg`}
              fallback2={`https://tourismebyca.twic.pics/static/tca/v2/etablissement/${item.data.tca_categ}.jpg`}
            />
          ) : (
            <CustomImage
              requirements={requirements}
              image={config.siteFallbackImage.image || ''}
              alt={config.siteFallbackImage.alt || ''}
              credit={config.siteFallbackImage.credit || ''}
            />
          )}
        </div>
        <span>{item.data.store_name}</span>
      </div>
      <Picto iconKey="burger-fr" width="14" height="14" />
    </div>
  );
}
