import { getStoreInfoFromSlug } from '../../tools/ciblerAPI';
import { RequirementsProps } from '../../tools/context';
import { getStoreInfoFromProvider, StoreInfoProvider } from '../../tools/providersAPI';
import { Renderer } from '../ShopV2';

const preloader = async (_: any, requirements: RequirementsProps) => {
  const sellerSlug = requirements.parameters[0];

  if (!!!sellerSlug) return null;

  const storeInfo = await getStoreInfoFromSlug(sellerSlug, requirements);
  const providers: StoreInfoProvider[] = ['apidae'];

  const provider = providers.find((p) => storeInfo.data[`${p}_id`]);
  const customData = provider
    ? await getStoreInfoFromProvider(requirements, provider, storeInfo.data[`${provider}_id`])
    : {};

  return {
    ...storeInfo,
    customData,
  };
};

const headers = (state: any) => {
  const headers = [];

  headers.push(`<link rel="canonical" href="/fiche-sit/${state?.data?.slug}">`);

  if (state?.seller?.name) {
    headers.push(`<meta name="name" content="${state?.seller?.name}">`);
    headers.push(`<title>${state?.seller?.name}</title>`);
    headers.push(`<meta property="og:title" content="${state?.seller?.name}">`);
    headers.push(`<meta property="og:site_name" content="${state?.seller?.name}">`);
    headers.push(`<meta property="og:url" content="/fiche-sit/${state?.data?.slug}">`);
    headers.push(`<meta property="og:type" content="website">`);
  }

  if (state?.data || state?.customData) {
    const d = state.data;
    const cD = state.customData?.fr;
    const addressFromCustomData = cD?.store?.address;

    headers.push(
      `<meta name="address" content="${addressFromCustomData?.address1 || d.street || ''} ${
        addressFromCustomData?.postalCode || d?.zipcode || ''
      } ${cD?.city || d?.city || ''}">`
    );
    headers.push(
      `<meta name="description" content="${cD?.store?.description || state?.data.description || ''}">`
    );

    if (state.data.noindex === 'true') {
      headers.push('<meta name="robots" content="noindex">');
      headers.push('<meta name="googlebot" content="noindex">');
    }
  }

  if (state?.images?.length) {
    headers.push(`<meta property="og:image" content="${state.images[0]}">`);
  }

  return headers;
};

const Shop = { Renderer, preloader, headers };

export default Shop;
export { Renderer, preloader };
