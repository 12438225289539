const martinique = {
  key: 'martinique',
  canonicalUrl: 'https://www.martinique.org',
  datalayerId: 'GTM-N7FRS7X',
  customerId: 678,
  icon: 'martinique.png',
  headerLogo: 'martinique.png',
  footerLogo: 'martinique_monochrome.png',
  axeptioClientId: '',
  axeptioCookiesVersion: 'guide.martinique.org',
  clientUrl: 'https://www.martinique.org/',
  proUrl: 'https://www.martinique.org/',
  languageSelector: true,
  sanity: {
    base: {
      projectId: 'qifuf266',
      dataset: 'production',
      apiVersion: '2022-12-01',
      useCdn: true,
    },
    preview: {
      useCdn: false,
      withCredentials: true,
      token:
        'sk1TZcO3SzZxq4JIgy9DncfMg8KVK2pb61LhHV4J8byEm3coxeTjBvkdrzYYJKz5zAZdU7qj2xhvhDZuMOIsjKSMVEtSzVqdMm9xlF55Kns2PhK0wlRBAltwYrjsQD1IUK4P00gNDieyH9aYiKlWhxpCwivOsjhDLn56dcweFhp4cWOY09WS',
    },
  },
  dev: {
    clientUrl: 'https://www.martinique.org/',
    proUrl: 'https://www.martinique.org/',
  },
  travelForceLogin: false,
};

export default martinique;