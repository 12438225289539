import React, { useEffect, useState } from 'react';
import uuidv4 from 'uuid/v4';
import { useSearchBox } from '../../contexts/SearchBoxContext';
import { eventTracking } from '../../tools/eventTracking';
import useTypingAnimation from '../../tools/hookTypingAnimation';
import { dynamicClassName } from '../../tools/tools';
import FloatingSearchBarInput from './FloatingSearchBarInput';

const pathToExclude = ['/engine'];
const TYPING_SPEED = 150;
const PAUSE_TIME = 1000;
const NAV_BAR_HEIGHT = 71;
const inputValidation = (query: string): void => {
  const chat_id = uuidv4();
  const encodeQuery = encodeURIComponent(query);
  if (typeof window !== 'undefined') {
    eventTracking('interaction', 'clic-barre-recherche', 'clic-barre-recherche');
    window.location.href = `/engine#/engine/charentes-engine/chat?chat_id=${chat_id}&query=${encodeQuery}`;
  }
};

interface FloatingSearchBarProps {
  placeholderSentences?: string[];
}

const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, query: string) => {
  if (e.key === 'Enter') {
    inputValidation(query);
  }
};

const FloatingSearchBar: React.FC<FloatingSearchBarProps> = ({ placeholderSentences }) => {
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [scrollY, setScrollY] = useState<number>(0);
  const [input, setInput] = useState<string>('');
  const [path, setPath] = useState<string>('');
  const { searchBoxPosition } = useSearchBox();
  const [query] = React.useState('');
  const [isSearchBarVisible, setIsSearchBarVisible] = useState<boolean>(false);

  const shouldDisplayBar = (path: string, scrollY: number, searchBoxPosition: number): void => {
    if (pathToExclude.includes(path)) {
      setIsSearchBarVisible(false);
    } else {
      setIsSearchBarVisible(scrollY > searchBoxPosition - NAV_BAR_HEIGHT);
    }
  };

  const onInputChange = (e: string): void => setInput(e);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setPath(window.location.pathname);
      const onScroll = () => {
        if (window.scrollY !== scrollY) {
          setScrollY(window.scrollY);
        }
        shouldDisplayBar(path, window.scrollY, searchBoxPosition.bottomPosition);
      };
      window.addEventListener('scroll', onScroll);
      return () => {
        window.removeEventListener('scroll', onScroll);
      };
    }
  }, [scrollY, searchBoxPosition.bottomPosition]);

  const currentText = useTypingAnimation({ placeholderSentences, TYPING_SPEED, PAUSE_TIME, query });

  if (isSearchBarVisible) {
    return (
      <div className={dynamicClassName('floatingSearchBar-modal', isFocus)}>
        <FloatingSearchBarInput
          inputValidation={inputValidation}
          onInputChange={onInputChange}
          onKeyDown={onKeyDown}
          placeholder={currentText}
          input={input}
          isFocus={isFocus}
          onFocus={setIsFocus}
        />
      </div>
    );
  }
  return null;
};

export default FloatingSearchBar;
