import React, { Fragment } from 'react';
import Separator from '../../../../../components/Separator';
import { RequirementsProps } from '../../../../../tools/context';
import { useTranslation } from '../../../../../tools/i18n';
import FavoritesGroupComp from '../../../../favorites/components/Group';
import NewFavoritesGroupButton from '../../../../favorites/components/NewGroupButton';
import { useFavoritesGroups } from '../../../../favorites/store';

interface FavoritesPageProps {
  requirements: RequirementsProps;
}

const FavoritesPage = ({ requirements }: FavoritesPageProps) => {
  const { t } = useTranslation();
  const favoritesGroups = useFavoritesGroups();

  return (
    <div className="FavoritesPage">
      {favoritesGroups?.length ? (
        <div className="FavoritesPage__groups">
          {favoritesGroups
            .sort((a, b) => (a.updated_at > b.updated_at ? -1 : 1))
            .map((g) => (
              <Fragment key={g.id}>
                <FavoritesGroupComp favoritesGroup={g} requirements={requirements} />
                <Separator />
              </Fragment>
            ))}
        </div>
      ) : (
        <p
          className="FavoritesPage__empty"
          dangerouslySetInnerHTML={{
            __html: t('ssrComponents.auth.pages.favorites.empty'),
          }}
        />
      )}
      <NewFavoritesGroupButton />
    </div>
  );
};

export default FavoritesPage;
