import React, { useState } from 'react';
import SvgCarteOn from './Map';

type Route =
  | 'flowvelo'
  | 'scandiberique'
  | 'velodyssee'
  | 'canal2mer'
  | 'francette'
  | 'velideale'
  | null;

interface RouteInfo {
  id: Route;
  name: string;
  color: string;
  link?: string;
  description?: string;
  title?: string;
}

interface InteractiveMapProps {
  intro?: string;
  routes: {
    flowvelo?: { color: { hex: string }; link: string; description: string; title: string };
    scandiberique?: { color: { hex: string }; link: string; description: string; title: string };
    velodyssee?: { color: { hex: string }; link: string; description: string; title: string };
    canal2mer?: { color: { hex: string }; link: string; description: string; title: string };
    francette?: { color: { hex: string }; link: string; description: string; title: string };
    velideale?: { color: { hex: string }; link: string; description: string; title: string };
  };
  cityLinks?: {
    larochelle?: { url: string };
    marans?: { url: string };
    rochefort?: { url: string };
    confolens?: { url: string };
    aubeterresurdronne?: { url: string };
    saintes?: { url: string };
    marennes?: { url: string };
    saintjeandangely?: { url: string };
    cognac?: { url: string };
    jonzac?: { url: string };
    angouleme?: { url: string };
    larochefoucauld?: { url: string };
    ruffec?: { url: string };
  };
}

const InteractiveMap = ({ intro, routes, cityLinks = {} }: InteractiveMapProps) => {
  const [activeRoute, setActiveRoute] = useState<Route>(null);
  console.log('intro', intro);
  console.log('routes', routes);
  console.log('cityLinks', cityLinks);

  const stripPTags = (html: string) => {
    return html?.replace(/<\/?p>/g, '') || '';
  };

  const routesArray: RouteInfo[] = [
    {
      id: 'flowvelo',
      name: routes.flowvelo?.title || '',
      color: routes.flowvelo?.color?.hex || '#dba070',
      link: routes.flowvelo?.link,
      description: routes.flowvelo?.description,
    },
    {
      id: 'scandiberique',
      name: routes.scandiberique?.title || '',
      color: routes.scandiberique?.color?.hex || '#b03030',
      link: routes.scandiberique?.link,
      description: routes.scandiberique?.description,
    },
    {
      id: 'velodyssee',
      name: routes.velodyssee?.title || '',
      color: routes.velodyssee?.color?.hex || '#3d8ccc',
      link: routes.velodyssee?.link,
      description: routes.velodyssee?.description,
    },
    {
      id: 'canal2mer',
      name: routes.canal2mer?.title || '',
      color: routes.canal2mer?.color?.hex || '#002684',
      link: routes.canal2mer?.link,
      description: routes.canal2mer?.description,
    },
    {
      id: 'francette',
      name: routes.francette?.title || '',
      color: routes.francette?.color?.hex || '#c5d571',
      link: routes.francette?.link,
      description: routes.francette?.description,
    },
    {
      id: 'velideale',
      name: routes.velideale?.title || '',
      color: routes.velideale?.color?.hex || '#002684',
      link: routes.velideale?.link,
      description: routes.velideale?.description,
    },
  ];

  const handleMouseEnter = (route: Route) => {
    setActiveRoute(route);
  };

  const handleMouseLeave = () => {
    setActiveRoute(null);
  };

  const createStyledDescription = (description: string, title: string, id: string) => {
    return stripPTags(`<span class="route__${id}">${title} </span> ${description}`);
  };

  return (
    <div className="InteractiveMap">
      {routesArray.map((route) => (
        <style key={`style-${route.id}`}>
          {`li:hover .route__${route.id} { color: ${route.color}; transition: color 0.3s ease; } @media (max-width: 769px) { li .route__${route.id} { color: ${route.color}; }`}
        </style>
      ))}
      <div className="InteractiveMap__map">
        <div className="InteractiveMap__map__svg-container">
          <SvgCarteOn
            routes={routes}
            highlightedPath={activeRoute}
            cityLinks={cityLinks}
            className="svg-element"
          />
        </div>
      </div>
      <div className="InteractiveMap__description">
        <p>
          {intro ||
            'Avec des itinéraires variés et riches en paysages, les vélo-routes font de la Charente un paradis pour les amateurs de cyclotourisme.'}
        </p>
        <ul>
          {routesArray.map((route) => (
            <li
              key={route.id}
              onMouseEnter={() => handleMouseEnter(route.id)}
              onMouseLeave={handleMouseLeave}
            >
              {route.link ? (
                <a
                  href={route.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  dangerouslySetInnerHTML={{
                    __html: createStyledDescription(
                      route.description || route.name,
                      route.title || route.name,
                      route.id
                    ),
                  }}
                />
              ) : (
                route.name
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default InteractiveMap;
