interface LanguageHandlerConfig {
  shouldReloadWithParam: boolean;
}

export const specialLanguageConfigs: Record<string, LanguageHandlerConfig> = {
  martinique: {
    shouldReloadWithParam: true
  }
};

export const handleSpecialLanguageChange = (key: string | undefined, languageCode: string): void => {
  if (key && specialLanguageConfigs[key]?.shouldReloadWithParam) {
    let currentUrl = window.location.href;
    
    currentUrl = currentUrl
      .replace(/[?&]lang=[^&]+/, '') // Enlève ?lang=xx ou &lang=xx
      .replace(/\?&/, '?') // Nettoie la syntaxe si nécessaire
      .replace(/\?$/, ''); // Enlève le ? final si présent
    
    const separator = currentUrl.includes('?') ? '&' : '?';
    const newUrl = `${currentUrl}${separator}lang=${languageCode}`;
    
    window.location.replace(newUrl);
  }
}; 