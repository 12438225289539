export const languageSelectorStyles = {
    default: {
        
    },
    martinique: {
        container: {
            width: '130px',
            minWidth: '120px',
            color: 'red'
          },
          control: {
            border: 'none',
            fontWeight: '700',
            fontSize: '14px',
            boxShadow: 'none',
            cursor: 'pointer',
            textAlign: 'right',
            minHeight: '36px',
            hoverBgColor: '#f4f1e8',
          },
          option: {
            selectedBgColor: '#D4ECEC',
            focusedBgColor: '#D4ECEC',
            defaultBgColor: 'white',
          },
          dropdownIndicator: {
            color: '#25A09D',
          },
          menu: {
            boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
            borderRadius: '10px',
            marginTop: '4px',
            width: '130px',
            textAlign: 'center',
          },
          colors: {
            primary: '#25A09D',
            secondary: '#25A09D',
          },
          singleValue: {
            color: '#25A09D',
          }
    },

    france: {
      container: {
        width: '140px',
        minWidth: '120px',
      },
      control: {
        border: 'none',
        fontWeight: '700',
        fontSize: '14px',
        boxShadow: 'none',
        cursor: 'pointer',
        textAlign: 'right',
        minHeight: '36px',
        hoverBgColor: '#f4f1e8',
      },
      option: {
        selectedBgColor: '#f4f1e8',
        focusedBgColor: '#f5f5f5',
        defaultBgColor: 'white',
        color: '#000',
      },
      menu: {
        boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
        borderRadius: '10px',
        marginTop: '4px',
        width: '170px',
      },
      colors: {
        primary: '#333',
        secondary: '#666',
      }
    },
  };
