import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTracking } from "../../tools/analytics";
import PictoButton from "./PictoButton";
import Swal from 'sweetalert2';
export interface FavoritesProps {
  inputs: {
    search?: string
    searchMap?: string
    startDate: string
    tripLength: number
  }
}

const Favorites = (props: FavoritesProps) => {
    const { trackEvent } = useTracking();
    const [isActive, setIsActive] = useState(false);
    const currentUrl = "window.location.href";

    const getItineraryId = (url: string) => {
      const match = url.match(/itineraire-v2\/(\d+)/);
      return match ? match[1] : null;
    };

    // Fonction pour obtenir les options du cookie en fonction de l'environnement
    const getCookieOptions = () => {
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 30);
      
      // Options de base
      const options = [
        'path=/',
        `expires=${expirationDate.toUTCString()}`,
        'SameSite=Lax'  // Changé de Strict à Lax pour le développement
      ];
      
      // Ajouter domain et secure seulement en production
      if (!window.location.hostname.includes('localhost')) {
        options.push('domain=.martinique.org');
        options.push('Secure');
      }
      
      return options.join('; ');
    };

    useEffect(() => {
      const currentItineraryId = getItineraryId(currentUrl);

      const existingFavorites = document.cookie
        .split('; ')
        .find(row => row.startsWith('travelFavorites='));

      if (existingFavorites) {
        try {
          const favoritesArray = JSON.parse(decodeURIComponent(existingFavorites.split('=')[1]));
          
          const isFavorited = favoritesArray.some(f => {
            const favId = getItineraryId(f.url);
            return favId === currentItineraryId;
          });
          
          setIsActive(isFavorited);
        } catch (e) {
          console.error('Erreur parsing favoris:', e);
          setIsActive(false);
        }
      }
    }, [currentUrl]);

    const saveToFavorites = () => {
      trackEvent('toggle_favorite');
      const currentItineraryId = getItineraryId(currentUrl);
      
      let favoritesArray = [];
      const existingFavorites = document.cookie
        .split('; ')
        .find(row => row.startsWith('travelFavorites='));

      if (existingFavorites) {
        try {
          favoritesArray = JSON.parse(decodeURIComponent(existingFavorites.split('=')[1]));
          // Utiliser l'ID pour trouver l'élément existant
          const existingIndex = favoritesArray.findIndex(f => getItineraryId(f.url) === currentItineraryId);
          
          if (existingIndex !== -1) {
            favoritesArray.splice(existingIndex, 1);
            setIsActive(false);
          } else {
            const newFavorite = {
              destination: props.inputs.search || '',
              depart: props.inputs.startDate || moment().format('DD/MM'),
              duration: props.inputs.tripLength || 1,
              url: currentUrl
            };
            favoritesArray.push(newFavorite);
            setIsActive(true);
          }
        } catch (e) {
          console.error('Error parsing favorites:', e);
          favoritesArray = [];
        }
      } else {
        const newFavorite = {
          destination: props.inputs.search || '',
          depart: props.inputs.startDate || moment().format('DD/MM'),
          duration: props.inputs.tripLength || 1,
          url: currentUrl
        };
        favoritesArray.push(newFavorite);
        setIsActive(true);
      }

      favoritesArray = favoritesArray.filter(favorite => 
        favorite.destination && 
        favorite.depart && 
        favorite.depart !== 'Invalid date' &&
        favorite.duration > 0
      );

      // Utiliser les nouvelles options de cookie
      document.cookie = `travelFavorites=${encodeURIComponent(JSON.stringify(favoritesArray))}; ${getCookieOptions()}`;
      console.log('Cookie sauvegardé avec options:', getCookieOptions());

      Swal.fire({
        title: isActive ? 'Retiré des favoris' : 'Sauvegardé !',
        text: isActive ? 'Votre itinéraire a été retiré des favoris' : 'Votre itinéraire a été ajouté aux favoris',
        icon: isActive ? 'error' : 'success',
        timer: 2000,
        showConfirmButton: false
      });
    };

    return (
      <PictoButton
        className={`favorite-button ${isActive ? "active" : ""}`}
        iconKey="heart"
        onClick={saveToFavorites}
      />
    );
};

export default Favorites;
