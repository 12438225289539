import * as React from 'react';
import { useEffect, useState } from 'react';
import ButtonV2 from '../ButtonV2';
import { pictos } from '../Picto';

export interface AppPopupOption {
  value: string;
  picto?: keyof typeof pictos;
  label: string;
}

export interface AppPopupProps {
  title: string;
  subtitle?: string;
  localStorageKey: string;
  options: AppPopupOption[];
}

const AppPopup: React.FC<AppPopupProps> = ({ title, subtitle, localStorageKey, options }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const localStorageValue = localStorage.getItem(localStorageKey);
    if (localStorageValue) return;
    setIsOpen(true);
  }, [localStorageKey]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const handleClickOption = (option: AppPopupOption) => {
    localStorage.setItem(localStorageKey, option.value);
    setIsOpen(false);
  };

  return (
    <div className="AppPopup">
      <div className="AppPopup__Container">
        <div className="AppPopup__header">
          <h4>{title}</h4>
          {subtitle ? <p>{subtitle}</p> : null}
        </div>
        {options?.length ? (
          <div className="AppPopup__options">
            {options.map((option, index) => (
              <ButtonV2
                key={index}
                text={option.label}
                leftIcon={option.picto}
                onClick={() => handleClickOption(option)}
                className={option.value}
              />
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AppPopup;
