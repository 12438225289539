import React, {memo} from "react";
import {useEffect, useState} from "react";
import {CustomImageType} from "./types";
import CustomImage from "../CustomImage";
import {RequirementsProps} from "../../tools/context";

export interface ImageSliderProps {
  requirements?: RequirementsProps;
  images: CustomImageType[];
}

export const CustomImageSlider = memo((props: ImageSliderProps) => {
  const {requirements, images} = props;
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [images.length]);

  if (!images?.length)
    return null;

  return (
    <div className="imageSlider">
      {images.map((image, index) => (
        <div 
          key={index}
          className={`image-container ${index === currentIndex ? "active" : ""}`}
        >
          <CustomImage
            requirements={requirements}
            image={image.image}
            alt={image.alt}
            credit={image.credit}
          />
        </div>
      ))}
    </div>
  );
});
